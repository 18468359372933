import React from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin-top: 18px;
  width: 420px;
`;

const Box = styled.div`
  position: relative;
  background-image: url(${(props) => props.image});
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 150px;
  flex-direction: column;
  gap: 14px;
  border-radius: 8px;
  overflow: hidden;
  margin-top: 8px;
  width: 420px;
  cursor: pointer;
`;

const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(41, 24, 94, 0.7);
  border-radius: 8px; /* Zaokrąglenie krawędzi */
  width: 420px;
`;

const Title = styled.div`
  color: #fff;
  text-align: center;
  font-family: "Work Sans";
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%;
  z-index: 1;
  width: 420px;
`;

const Subtitle = styled.div`
  color: #fff;
  text-align: center;
  font-family: "Work Sans";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 110%;
  width: 420px;
  z-index: 1;
`;

const TitleTop = styled.div`
  color: #29185e;
  font-family: "Work Sans";
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%; /* 25.2px */
  text-align: center;
  width: 420px;
`;

const NewsDesktop = ({ news = {}, translation }) => {
  const navigate = useNavigate();

  return (
    <Wrapper>
      <TitleTop>{translation.funFact}</TitleTop>
      <Box
        image={news.image?.base64}
        onClick={() => {
          navigate(`/learn/chat/${news._id}`);
        }}
      >
        <Overlay /> {/* Nakładka */}
        <Title>{translation.doYouKnow}</Title>
        <Subtitle>{news.description}</Subtitle>
      </Box>
    </Wrapper>
  );
};

export default NewsDesktop;
