import userManager from '@/api/userManager';
import Api from '@/api/api';
import { IP_STATS_ENUM } from './enums/ipStatsEnum';
const { axios: api } = Api;

export const getPermission = async (handleSendIPstats) => {
  const urlBase64ToUint8Array = (base64String) => {
    const padding = '='.repeat((4 - (base64String.length % 4)) % 4);
    const base64 = (base64String + padding)
      .replace(/-/g, '+')
      .replace(/_/g, '/');
    const rawData = window.atob(base64);
    return new Uint8Array([...rawData].map((char) => char.charCodeAt(0)));
  };

  await navigator.serviceWorker.ready.then(async function (registration) {
    const vapidPublicKey = process.env.REACT_APP_PUBLIC_KEY;
    const convertedVapidKey = urlBase64ToUint8Array(vapidPublicKey);

    try {
      await registration?.pushManager
        ?.subscribe({
          userVisibleOnly: true,
          applicationServerKey: convertedVapidKey,
        })
        .then(async function (subscription) {
          const user = userManager?.getUser();
          const registerUser = userManager?.getUserRegister();

          const result = subscription.toJSON();
          result.user = user?.id || registerUser?.id;
          result.uid = window.localStorage.getItem('uid');
          result.api_address = `https://app.synthiaiapp.com/api/v1/`;

          await api.post('web-push', result);

          return subscription;
        })
        .catch(function (error) {
          handleSendIPstats(IP_STATS_ENUM.NOTIFICATION_REQUEST_DENIED);
          console.error('Failed to subscribe the user: ', error);
        });
    } catch {}
  });
};
