import React from "react";
import TranslationIcon from "@/common/images/icon-translate.svg";
import { CustomImage } from "./translationBotMessage.styles";

const TranslationBotMessage = ({ show, setShowTrnaslation }) => {
  return (
    show && (
      <CustomImage
        className="fade__in__animation"
        src={TranslationIcon}
        alt="translation-icon"
        onClick={setShowTrnaslation}
      />
    )
  );
};

export default TranslationBotMessage;
