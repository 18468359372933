import React, { useContext } from "react";
import styled from "styled-components";
import New from "../desktop/images/new.png";
import CallMode from "../desktop/images/callmode.png";
import { Link } from "react-router-dom";
import { LanguageContext } from "@/common/contexts/LanguageContext";

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100vw;
  margin-top: 18px;
`;

const Box = styled(Link)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  background-image: url(${(props) => props.image});
  background-size: cover;
  background-position: center;

  width: 94vw;
  margin-left: 3vw;
  height: 150px;
  gap: 14px;
  border-radius: 8px;
  text-decoration: none;
  margin-top: 8px;
`;

const Title = styled.div`
  color: #fff;
  font-family: "Work Sans";
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%; /* 28px */
`;

const Subtitle = styled.div`
  color: #fff;
  text-align: center;
  font-family: "Work Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 110%; /* 15.4px */
  max-width: 300px;
`;

const TitleTop = styled.div`
  color: #29185e;
  font-family: "Work Sans";
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%; /* 25.2px */
  text-align: left;
  margin-left: 3vw;
`;

const NewSection = ({ newInfo, translation, tutor }) => {
  const { lang } = useContext(LanguageContext);

  return (
    <>
      <Wrapper>
        <TitleTop>{translation.new}</TitleTop>
        <Box
          image={lang === "pl" ? CallMode : New}
          to={lang === "pl" ? "/learn/call-mode" : "/learn/scenes"}
        >
          <Title>{translation.newTitle}</Title>
          <Subtitle>
            {lang === "pl"
              ? `${translation.newSubtitle} ${tutor?.name || "Synthi"}`
              : translation.newSubtitle}{" "}
          </Subtitle>
        </Box>
      </Wrapper>
    </>
  );
};

export default NewSection;
