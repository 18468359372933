import { styled } from "styled-components";

export const CustomImage = styled.img`
  width: 30px;
  height: 30px;

  &:hover {
    cursor: pointer;
    transform: scale(1.05);
  }

  @media (max-width: 600px) {
    width: 20px;
    height: 20px;
  }
`;

export const CustomText = styled.p`
  color: #c1c1c1;
  font-size: 12px;
  max-width: 120px;
  margin: 0;

  font-style: normal;
  line-height: 140%;
`;
