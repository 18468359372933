import React from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100vw;
  margin-top: 18px;
`;

const Box = styled.div`
  position: relative;
  background-image: url(${(props) => props.image});
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 90vw;
  height: 150px;
  flex-direction: column;
  gap: 14px;
  border-radius: 8px;
  overflow: hidden;
  width: 94vw;
  margin-left: 3vw;
  margin-top: 8px;
`;

const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(41, 24, 94, 0.7); /* Kolor nakładki */
  border-radius: 8px; /* Zaokrąglenie krawędzi */
`;

const Title = styled.div`
  color: #fff;
  text-align: center;
  font-family: 'Work Sans';
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%;
  z-index: 1;
`;

const Subtitle = styled.div`
  color: #fff;
  text-align: center;
  font-family: 'Work Sans';
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 110%;
  max-width: 300px;
  z-index: 1;
`;

const TitleTop = styled.div`
  color: #29185e;
  font-family: 'Work Sans';
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%; /* 25.2px */
  text-align: left;
  width: 360px;
  margin-left: 3vw;
`;

const News = ({ news = {}, translation }) => {
  const navigate = useNavigate();

  return (
    <Wrapper>
      <TitleTop>{translation.funFact}</TitleTop>
      <Box
        image={news.image?.base64}
        onClick={() => {
          navigate(`/learn/chat/${news._id}`);
        }}
      >
        <Overlay /> {/* Nakładka */}
        <Title>{translation.doYouKnow}</Title>
        <Subtitle>{news.description}</Subtitle>
      </Box>
    </Wrapper>
  );
};

export default News;
