export function jaroWinkler(s1, s2) {
  const m = 0.1;
  let mt = 0;
  let transpositions = 0;
  const s1_len = s1.length;
  const s2_len = s2.length;

  const matchDistance = Math.floor(Math.max(s1_len, s2_len) / 2) - 1;
  const s1_matches = new Array(s1_len).fill(false);
  const s2_matches = new Array(s2_len).fill(false);

  for (let i = 0; i < s1_len; i++) {
    const start = Math.max(0, i - matchDistance);
    const end = Math.min(i + matchDistance + 1, s2_len);

    for (let j = start; j < end; j++) {
      if (s2_matches[j]) continue;
      if (s1[i] !== s2[j]) continue;
      s1_matches[i] = true;
      s2_matches[j] = true;
      mt++;
      break;
    }
  }

  if (mt === 0) return 0;

  let k = 0;
  for (let i = 0; i < s1_len; i++) {
    if (!s1_matches[i]) continue;
    while (!s2_matches[k]) k++;
    if (s1[i] !== s2[k]) transpositions++;
    k++;
  }

  transpositions /= 2;

  const jaro = (mt / s1_len + mt / s2_len + (mt - transpositions) / mt) / 3;
  const commonPrefixLength = Math.min(4, s1_len, s2_len);
  let prefixLength = 0;
  for (let i = 0; i < commonPrefixLength; i++) {
    if (s1[i] === s2[i]) {
      prefixLength++;
    } else {
      break;
    }
  }

  return jaro + prefixLength * m * (1 - jaro);
}
