const startTyping = (
  i,
  text,
  setText,
  typing = 45,
  onEnd = () => {},
  timeout,
  shouldType,
) => {
  if (i <= text.length) {
    // if (shouldType) {
    setText(text.slice(0, i));
    i++;
    // } else {
    //   i = text.length;
    //   setText(text);
    // }

    return setTimeout(() => {
      startTyping(i, text, setText, typing, onEnd, timeout, shouldType);
    }, typing);
  }

  if (i > text.length) {
    return setTimeout(() => {
      onEnd();
    }, timeout);
  }
};

export const typeWriter = ({
  text,
  setText,
  onEnd = () => {},
  audio,
  typing = 40,
  timeout = 1200,
  shouldType,
}) => {
  audio && audio.play();
  startTyping(0, text, setText, typing, onEnd, timeout, shouldType);
};
