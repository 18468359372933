import PopUp from '@/common/components/PopUp';
import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import HandDown from '../images/hand-down.svg';
import HandUp from '../images/hand-up.svg';
import ArrLeft from '../images/arr-left-popup.svg';
import ArrRight from '../images/arr-right-popup.svg';
import { useNavigate } from 'react-router-dom';
import { HAND_DECISION } from '@/common/components/customer/Hands';
import HandPopUp from './HandPopUp';
import { IpStatsContext } from '@/common/contexts/IpStatsContext';
import { IP_STATS_ENUM } from '@/common/enums/ipStatsEnum';

const Title = styled.div`
  color: #29185e;
  text-align: center;
  font-family: 'Work Sans';
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%; /* 19.2px */
`;

const DownButtonsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

const DownButton = styled.div`
  color: #29185e;
  text-align: center;
  font-family: 'Work Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.56px;
  text-transform: uppercase;
`;

const DownButtonsElementWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
`;

const HandsWrapper = styled.div`
  display: flex;
  justify-content: center;
  gap: 50px;
  margin: 24px 0 44px 0;
`;

const ImageWrapper = styled.div`
  width: 15px;
  overflow: hidden;

  img {
    width: 100%;
    height: auto;
  }
`;

const Wrapper = styled.div`
  width: 480px;
  padding: 22px 10px 10px 10px;

  @media (max-width: 600px) {
    width: 280px;
  }
`;

const EndPopUp = ({ setClose, playEnd }) => {
  const [handPopUp, setHandPopUp] = useState();
  const { handleSendIPstats } = useContext(IpStatsContext);

  useEffect(() => {
    handleSendIPstats(IP_STATS_ENUM.APP_CALL_MODE_POPUP_HANDS);
  }, []);

  return (
    <>
      <PopUp setClose={setClose} closeIcon={false}>
        <Wrapper>
          <Title>Jak oceniasz rozmowę?</Title>
          <HandsWrapper>
            <div onClick={() => setHandPopUp(() => HAND_DECISION.UP)}>
              <img src={HandUp} />
            </div>
            <div onClick={() => setHandPopUp(() => HAND_DECISION.DOWN)}>
              <img src={HandDown} />
            </div>
          </HandsWrapper>
          <DownButtonsWrapper>
            <DownButtonsElementWrapper
              onClick={() => {
                handleSendIPstats(IP_STATS_ENUM.APP_CALL_MODE_POPUP_HANDS_BACK);
                setClose(() => false);
              }}
            >
              <ImageWrapper>
                <img src={ArrLeft} />
              </ImageWrapper>
              <DownButton>WRÓĆ</DownButton>
            </DownButtonsElementWrapper>
            <DownButtonsElementWrapper
              onClick={() => {
                handleSendIPstats(IP_STATS_ENUM.APP_CALL_MODE_POPUP_HANDS_SKIP);
                playEnd();
              }}
            >
              <DownButton>POMIŃ</DownButton>
              <ImageWrapper>
                <img src={ArrRight} />
              </ImageWrapper>
            </DownButtonsElementWrapper>
          </DownButtonsWrapper>
        </Wrapper>
      </PopUp>
      {handPopUp && <HandPopUp playEnd={playEnd} handPopUp={handPopUp} />}
    </>
  );
};

export default EndPopUp;
