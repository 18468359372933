import PopUp from '@/common/components/PopUp';
import React from 'react';
import styled from 'styled-components';

const Text = styled.div`
  color: #05275d;

  text-align: center;
  font-family: Work Sans;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%; /* 33.6px */
`;

const NoButton = styled.button`
  display: inline-flex;
  padding: 15px 50px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 100px;
  background: #aba9a9;
  min-width: 60px;
  color: white;

  @media (max-width: 600px) {
    padding: 5px 30px;
  }
`;

const YesButton = styled.button`
  display: inline-flex;
  padding: 15px 50px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 100px;
  background: #650675;
  min-width: 60px;
  color: white;

  @media (max-width: 600px) {
    padding: 5px 30px;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  gap: 40px;
  justify-content: center;
  margin-top: 40px;
`;

const Wrapper = styled.div`
  padding: 60px 40px;

  @media (max-width: 600px) {
    padding: 30px 10px;
    max-width: 300px;
  }
`;

const CancelPopUp = ({ setClose, handleSave }) => {
  return (
    <PopUp setClose={setClose} closeIcon={false}>
      <Wrapper>
        <Text>Czy chcesz anulować subskrypcję?</Text>
        <ButtonWrapper>
          <NoButton onClick={() => setClose(null)}>NIE</NoButton>
          <YesButton onClick={() => handleSave.mutate()}>TAK</YesButton>
        </ButtonWrapper>
      </Wrapper>
    </PopUp>
  );
};

export default CancelPopUp;
