import Api from '../api';
const { axios: api } = Api;
const PREFIX = 'word-lesson-type';

export const findWordLesson = async (payload) => {
  const data = await api.get(`/${PREFIX}/${payload}`);
  return { data: data.data };
};

export const getWordLessonTypeCustomer = async (payload) => {
  const data = await api.get(`/${PREFIX}/customer-all`, payload);
  return { data: data.data };
};
