export const EventServiceFactory = () => {
  const subscribe = (eventName, callback) => {
    window.addEventListener(eventName, callback);
    return () => unsubscribe(eventName, callback);
  };

  const subscribeOnce = (eventName, callback) => {
    const unsubscribeCurrentEvent = subscribe(eventName, (e) => {
      callback(e);
      unsubscribeCurrentEvent();
    });

    return unsubscribeCurrentEvent;
  };

  const publish = (eventName, payload) => {
    const customEvent = new CustomEvent(eventName, payload);
    window.dispatchEvent(customEvent);
  };

  const unsubscribe = (eventName, callback) =>
    window.removeEventListener(eventName, callback);

  return {
    subscribe,
    publish,
    subscribeOnce,
  };
};
