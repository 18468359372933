import { styled } from 'styled-components';

export const ContentWrapper = styled.div`
  display: flex;
  justify-content: left;
  gap: 20px;
  flex-grow: 1;

  @media (max-width: 600px) {
    gap: 10px;
  }
`;

export const MessageBox = styled.div`
  display: flex;
  gap: 0px;
  flex-direction: column;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  height: calc(var(--vh, 1vh) * 100 - 190px);
  margin: 0 10px;

  @media (min-width: 600px) {
    gap: 0px;
    flex-grow: 1;
    padding: 0;
    height: calc(var(--vh, 1vh) * 100 - 290px);
  }
`;

export const Logo = styled.img`
  width: 69px;
  height: 75px;

  @media (max-width: 600px) {
    width: 30px;
    height: 32px;
  }
`;

export const Height = styled.div`
  min-height: 1000px;
`;

export const DemoSectionWrapper = styled.div`
  width: 100%;
  height: calc(var(--vh, 1vh) * 100 - 117px);
  top: 57px;
  position: absolute;
  background: #fff;

  @media (min-width: 600px) {
    box-sizing: border-box;
    height: fit-content;
    width: 100%;
    position: static;
    box-shadow: none;
    padding: 30px 8px 6px;
    flex-grow: 1;
    display: flex;
    gap: 20px;
  }
`;
