import React from "react";
import styled, { css, keyframes } from "styled-components";
import FiledStar from "@/common/icons/stars/filled-star.svg";
import PopUp from "@/common/components/PopUp";

const slideInFromTop = keyframes`
  0% {
    transform: translateY(-100%);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
`;

const Wrapper = styled.div`
  padding: 30px 100px;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 20px;
  justify-content: center;
  align-items: center;
  border-radius: 0px 0px 16px 16px;
  animation: ${slideInFromTop} 0.5s ease-in-out forwards;
`;

const Text = styled.div`
  color: #05275d;

  text-align: center;
  font-family: Work Sans;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%; /* 33.6px */
`;

const Texts = [
  "Świetnie Ci idzie! ",
  "Brawo!",
  "Cel osiągnięty!",
  "Tak trzymaj!",
];

const StartInfo = ({ showStarInfo, setShow }) => {
  return (
    <PopUp setClose={setShow} closeIcon={false}>
      <Wrapper>
        <Text>{Texts[Math.floor(Math.random() * 4)]}</Text>
        <img src={FiledStar} style={{ width: "100px" }} />
        <Text>You're getting a star!</Text>
      </Wrapper>
    </PopUp>
  );
};

export default StartInfo;
