import Api from '../api';
const { axios: api } = Api;
const PREFIX = 'lesson';

export const findLessonsForCustomer = async () => {
  const data = await api.get(`/${PREFIX}/for-customer`);

  return { data: data.data };
};

export const findCustomerLessonById = async (_id) => {
  const data = await api.get(`/${PREFIX}/customer-one/${_id}`);

  return { data: data.data };
};
