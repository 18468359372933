import Api from '../api';
const { axios: api } = Api;

const PREFIX = 'flashcard-categories';
export const createFlashcardCategoryByCustomer = async (payload) => {
  const data = await api.post(`/${PREFIX}/customer`, payload);
  return { data: data.data };
};

export const generateWordsFlashCardTopic = async (payload) => {
  const data = await api.post(`/${PREFIX}/generate-words/${payload._id}`);
  return { data: data.data };
};

export const saveWordsFashcard = async (payload) => {
  const data = await api.post(
    `/${PREFIX}/generate-words-algo/saveWords`,
    payload,
  );
  return { data: data.data };
};

export const getWordLessonTopTypeByHobby = async (payload) => {
  const data = await api.get(`/${PREFIX}/${payload._id}`);
  return { data: data.data };
};
