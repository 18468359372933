import React, {
  useContext,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import { useMutation } from 'react-query';
import { Link, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import Loading from '@/common/components/Loading';
import { getWordLessonTypeCustomer } from '@/api/query/word-lesson-type';
import ArrLeft from '@/common/images/arr-left.svg';
import FilledHeart from '../lessons/images/filled-heart.svg';
import BlankHeart from '../lessons/images/blank-heart.svg';
import ArrRightViolet from './images/arr-right-violet.svg';
import WriteOpinion, {
  OPINION_OPTIONS,
} from '../profile/components/WriteOpinion';
import { useLanguageData } from '@/common/hooks/useLanguageData';
import { CustomerDataContext } from '@/common/components/layout/productLayout/context/CustomerDataContext';

export const Wrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100vw;
  height: calc(var(--vh, 1vh) * 100 - 60px);
  overflow-y: auto;
  text-align: center;
  padding: 10px 10px 0 10px;
  gap: 10px;

  @media (min-width: 600px) {
    flex-grow: 1;
    height: calc(var(--vh, 1vh) * 100 - 160px);
    width: 100%;
    padding: 30px 30px 10px;
    margin: 0;
  }
`;

export const Title = styled.h2`
  color: #05275d;
  text-align: center;
  font-family: Work Sans;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.56px;
  margin: 0px;
  text-align: center;
  margin: 10px 0;
`;

const BoxWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  overflow: auto;
  justify-content: center;
  gap: 16px;
  padding-bottom: 20px;
`;

const ImageOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(41, 24, 94, 0.5); /* Kolor nakładki */
  border-radius: 8px; /* Zaokrąglenie krawędzi */
`;

const Heart = styled.div`
  position: absolute;
  right: 5px;
  bottom: 0px;
  width: 25px;
  z-index: 2;

  img {
    width: 100%;
    height: auto;
  }
`;

const TopTitle = styled.div`
  color: #fff;
  text-align: center;
  font-family: 'Work Sans';
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 123.5%; /* 24.7px */
  letter-spacing: 0.4px;
  position: absolute;
  color: white;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;

  div {
    max-width: 130px;
  }
`;

const SubTitle = styled.div`
  color: #29185e;
  text-align: center;
  font-family: 'Work Sans';
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 110%; /* 17.6px */
  max-width: 500px;
`;

const Box = styled(Link)`
  position: relative; /* Ustawiamy pozycję na relative */
  width: 160px;
  height: 160px;
  border-radius: 8px; /* Zaokrąglenie krawędzi */
  background-color: white !important;
  box-shadow: 0px 0px 11px 0px rgba(79, 167, 180, 0.2);
`;

const ImageWrapper = styled.div`
  width: 160px;
  height: 160px;
  position: relative; /* Ustawiamy pozycję na relative */
  overflow: hidden;

  img {
    border-radius: 8px;
    width: 100%;
    height: 100%;
  }
`;

export const BoxOpinion = styled.div`
  cursor: pointer;
  display: inline-flex;
  padding: 10px 66px 10px 75px;
  justify-content: flex-end;
  align-items: center;
  border-radius: 8px;
  background: #fff;
  color: #730bdb;
  text-align: center;
  font-family: 'Work Sans';
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%; /* 16.8px */

  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;

  div {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  @media (min-width: 600px) {
    width: 50vw;
  }
`;

const WordLessonTypeList = () => {
  const [wordsTypes, setWordsTypes] = useState([]);
  const [showOption, setShowOpinion] = useState();
  const { title, subtitle, suggest } = useLanguageData('features.word');

  const handleLoadWordsListMutation = useMutation({
    mutationFn: () => getWordLessonTypeCustomer(),
    onSuccess: ({ data }) => {
      setWordsTypes(data);
    },
  });

  const { manageFavoriteMutation, favoriteArr } =
    useContext(CustomerDataContext);

  const handleAddToFavorite = (id, type) => {
    const payload = {
      _id: id,
      type,
      shouldAdd: favoriteArr?.includes(id) ? false : true,
    };

    manageFavoriteMutation.mutate(payload);
  };

  useLayoutEffect(() => {
    handleLoadWordsListMutation.mutate();
    window.document.title = 'Synthi Ai - Słowa';
  }, []);

  return (
    <>
      {handleLoadWordsListMutation.isLoading && <Loading />}
      <Wrapper height={window.innerHeight}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '100%',
          }}
        >
          <Link to={'/learn'}>
            <img
              src={ArrLeft}
              style={{
                width: '12px',
              }}
            />
          </Link>
          <Title>{title}</Title>
          <div></div>
        </div>
        <SubTitle>{subtitle}</SubTitle>
        <BoxWrapper>
          {wordsTypes.map((d) => (
            <Box to={`/learn/words/${d._id}`}>
              <ImageWrapper>
                <TopTitle>
                  <div>{d.translation}</div>
                </TopTitle>
                <img src={d.file.base64} alt={d.name} />
                <ImageOverlay />
                <Heart
                  onClick={(e) => {
                    e?.preventDefault();
                    e?.stopPropagation();
                    handleAddToFavorite(d._id, 'words');
                  }}
                >
                  <img
                    src={favoriteArr.includes(d._id) ? FilledHeart : BlankHeart}
                  />
                </Heart>
              </ImageWrapper>
            </Box>
          ))}
          <BoxOpinion onClick={() => setShowOpinion(true)}>
            <div>{suggest}</div>
            <div>
              <img src={ArrRightViolet} />
            </div>
          </BoxOpinion>
        </BoxWrapper>
      </Wrapper>
      {showOption && (
        <WriteOpinion setShow={setShowOpinion} from={OPINION_OPTIONS.WORD} />
      )}
    </>
  );
};

export default WordLessonTypeList;
