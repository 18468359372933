import React, { useEffect, useState } from 'react';
import { createContext } from 'react';
import CookieConsent from '../components/CookieConsent';
import { useMutation } from 'react-query';
import { createCookie, loadCookie } from '@/api/query/cookie';

export const CookieContext = createContext();

export const COOKIE_STATUS = {
  ALLOW: 'allow',
  DENAIED: 'denaied',
  NO_DECISION: 'no_decision',
  LOADING: 'loading',
};

const CookieContextProvider = ({ children }) => {
  const [allowCookie, setAllowCookie] = useState({
    status: COOKIE_STATUS.LOADING,
  });

  const handleSetCookieMutation = useMutation({
    mutationFn: (data) => createCookie(data),
    onSuccess: ({ data }) => {
      setAllowCookie(data);
    },
  });

  const handleLoadCookieMutation = useMutation({
    mutationFn: (data) => loadCookie(data),
    onSuccess: ({ data }) => {
      setAllowCookie(data || { status: COOKIE_STATUS.NO_DECISION });
    },
  });

  const handleCookieDecision = (status) => {
    const payload = {
      status,
      consent_uid: localStorage.getItem('cookie_consent_uid'),
      uid: localStorage.getItem('uid'),
    };

    handleSetCookieMutation.mutate(payload);
  };

  const generateUid = () => {
    let uid =
      new Date().getTime().toString(16) + Math.floor(Math.random() * 1000);

    localStorage.setItem('cookie_consent_uid', uid);
    return uid;
  };

  useEffect(() => {
    let cookie = localStorage.getItem('cookie_consent_uid');

    if (!cookie) {
      cookie = generateUid();
    }

    const payload = {
      consent_uid: cookie,
    };

    handleLoadCookieMutation.mutate(payload);
  }, []);

  return (
    <CookieContext.Provider
      value={{
        handleCookieDecision,
      }}
    >
      {children}
      {allowCookie?.status === COOKIE_STATUS.NO_DECISION && <CookieConsent />}
    </CookieContext.Provider>
  );
};

export default CookieContextProvider;
