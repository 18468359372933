class UserManager {
  setUser(user) {
    let payload = JSON.stringify(user);
    localStorage.setItem('user', payload);
  }

  getUser() {
    return localStorage.getItem('user')
      ? JSON.parse(localStorage.getItem('user'))
      : null;
  }

  getUserRegister() {
    return localStorage.getItem('user_register')
      ? JSON.parse(localStorage.getItem('user_register'))
      : null;
  }

  removeUser() {
    localStorage.removeItem('user');
  }
}

export default new UserManager();
