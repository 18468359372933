import React from "react";
import styled from "styled-components";
import chat from "../images/chat.png";
import { Link } from "react-router-dom";
import { useLanguageData } from "@/common/hooks/useLanguageData";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 40px;
`;

const Box = styled(Link)`
  background-image: url(${(props) => props.image});
  background-size: cover;
  background-position: left;
  height: 140px;
  margin-top: 12px;
  border-radius: 8px;
  overflow: hidden;
  text-decoration: none;
  width: 380px;
  margin-left: -12px;
`;

const Subtitle = styled(Link)`
  color: #29185e;
  font-family: "Work Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 123.5%; /* 17.29px */
  letter-spacing: 0.28px;
  text-align: left;
  text-decoration: none;
`;

const TitleTop = styled(Link)`
  color: #29185e;
  font-family: "Work Sans";
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%; /* 25.2px */
  text-align: left;
  text-decoration: none;
`;

const ChatDesktop = () => {
  const data = useLanguageData("learn.chat");

  return (
    <Wrapper>
      <TitleTop to={"/learn/chat"}>{data.title}</TitleTop>
      <Subtitle to={"/learn/chat"}>{data.subtitle}</Subtitle>
      <Box image={chat} to={"/learn/chat"}></Box>
    </Wrapper>
  );
};

export default ChatDesktop;
