import { styled } from 'styled-components';

export const CustomImage = styled.img`
  width: 20px;
  height: 20px;

  &:hover {
    cursor: pointer;
    /* transform: scale(1.05); */
  }
`;
