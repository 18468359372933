import styled from 'styled-components';
import Cookies from './images/cookies-image.svg';
import { useContext } from 'react';
import { useLanguageData } from '../hooks/useLanguageData';
import { COOKIE_STATUS, CookieContext } from '../contexts/CookieContext';
import { generateLinkToProductPage } from '../functions/generateLinkToProductPage';
import { LanguageContext } from '../contexts/LanguageContext';

const CookieWrapper = styled.div`
  position: absolute;
  background-color: red;
  width: 100vw;
  height: 200px;
  bottom: 0;
  background: var(
    --Purple,
    linear-gradient(91deg, #4f17aa 1.48%, #ac02f1 99.24%)
  );
  z-index: 999999;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 26px;

  @media (min-width: 600px) {
    flex-direction: row;
    height: fit-content;
    padding: 20px;
    box-sizing: border-box;
    justify-content: space-evenly;
  }
`;

const TopWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 22px;

  color: #fff;
  text-align: center;
  font-family: 'Lato';
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;

const MiddleText = styled.div`
  color: #fff;
  text-align: center;
  font-family: 'Lato';
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%; /* 14.4px */
  max-width: 350px;

  a {
    color: #fff;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  gap: 38px;
`;

const ButtonDenied = styled.button`
  display: inline-flex;
  padding: 10px 26px;
  justify-content: center;
  align-items: center;
  gap: 10px;

  border-radius: 100px;
  border: 2px solid #ece5f2;

  color: #ece5f2;
  text-align: center;
  font-family: 'Work Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.64px;
  text-transform: uppercase;
  background: transparent;
  cursor: pointer;
`;

const ButtonAccept = styled.button`
  display: inline-flex;
  padding: 10px 12px;
  justify-content: center;
  align-items: center;
  gap: 10px;

  border-radius: 100px;
  background: var(--Background-pinish, #ece5f2);

  color: #730bdb;
  text-align: center;
  font-family: 'Work Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.64px;
  text-transform: uppercase;
  cursor: pointer;

  border: none;
`;

const FlexTop = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (min-width: 600px) {
    gap: 10px;
  }
`;

const CookieConsent = () => {
  const { handleCookieDecision } = useContext(CookieContext);
  const data = useLanguageData('cookie');
  const common = useLanguageData('common');
  const { lang } = useContext(LanguageContext);

  return (
    <CookieWrapper>
      <FlexTop>
        <TopWrapper>
          <div>
            <img src={Cookies} />
          </div>
          <div>{data?.title}</div>
        </TopWrapper>
        <MiddleText>
          {data?.subtitle}
          <a
            href={generateLinkToProductPage(lang, common.privacyLink)}
            target="_blank"
          >
            {data?.link}
          </a>
          .
        </MiddleText>
      </FlexTop>
      <ButtonWrapper>
        <ButtonDenied
          onClick={() => handleCookieDecision(COOKIE_STATUS.DENAIED)}
        >
          {data?.denied}
        </ButtonDenied>
        <ButtonDenied onClick={() => handleCookieDecision(COOKIE_STATUS.ALLOW)}>
          {data?.accept}
        </ButtonDenied>
      </ButtonWrapper>
    </CookieWrapper>
  );
};

export default CookieConsent;
