import React, { useCallback, useContext, useEffect, useState } from "react";
import MicRecorder from "mic-recorder-to-mp3";
import { useLongPress } from "use-long-press";
import styled from "styled-components";
import { IP_STATS_ENUM } from "../enums/ipStatsEnum";
import { IpStatsContext } from "../contexts/IpStatsContext";
import Icon from "../images/microfon-white.svg";
import { useLanguageData } from "../hooks/useLanguageData";

const Mp3Recorder = new MicRecorder({ bitRate: 128 });

const RecordingButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px 20px 8px 20px;
  border-radius: 100px;
  color: white;
  margin-bottom: 10px;
  background: var(
    --Purple,
    linear-gradient(91deg, #4f17aa 1.48%, #ac02f1 99.24%)
  );
  color: #fff;
  text-align: center;
  font-family: Work Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.8px;
  text-transform: uppercase;
  margin-top: 5px;
`;

const Microfon = ({ handleSend }) => {
  const [isBlocked, setIsBlocked] = useState(false);
  const [isRecording, setIsRecording] = useState(false);
  const { allowMicrofon, record, recording } = useLanguageData("talkingBar");

  const { handleSendIPstats } = useContext(IpStatsContext);

  const start = () => {
    if (isBlocked) {
      alert(allowMicrofon);
    } else {
      Mp3Recorder.start()
        .then(() => {
          setIsRecording(() => true);
        })
        .catch((e) => alert(allowMicrofon));
    }
  };

  const stop = () => {
    handleSendIPstats(IP_STATS_ENUM.TALKING_IN_FOTO_MODE);
    Mp3Recorder.stop()
      .getMp3()
      .then(([buffer, blob]) => {
        const file = new File([blob], "to_translate.mp3", {
          type: "audio/mpeg",
          lastModified: new Date().getTime(),
        });
        const formData = new FormData();
        formData.append("file", file);
        handleSend(formData);
        setIsRecording(false);
      })
      .catch((e) => console.log(e));
  };

  const handleRecording = () => {
    if (isRecording) {
      stop();
      setIsRecording(() => false);
      return;
    } else {
      start();
      setIsRecording(() => true);
    }
  };

  useEffect(() => {
    navigator.mediaDevices
      .getUserMedia({ audio: true })
      .then((stream) => {
        console.log("Permission Granted");
        setIsBlocked(() => false);
      })
      .catch((err) => {
        console.log("Permission Denied");
        console.log(err);
        setIsBlocked(() => true);
      });
  }, []);

  return (
    <RecordingButton
      style={{
        background: isRecording
          ? "red"
          : "linear-gradient(91deg, #4F17AA 1.48%, #AC02F1 99.24%)",
        cursor: "pointer",
      }}
      type="button"
      onClick={() => handleRecording()}
    >
      {isRecording ? recording : record}
      <img src={Icon} style={{ marginLeft: "10px" }} width={"14px"} />
    </RecordingButton>
  );
};

export default Microfon;
