import Api from '../api';
const { axios: api } = Api;
const PREFIX = 'image-mode';

export const getUserImageMode = async () => {
  const data = await api.get(`/${PREFIX}/get-user-image-mode-data`);
  return { data: data.data };
};

export const changeTaskInImageMode = async (value) => {
  const data = await api.post(`/${PREFIX}/changeTask/${value.imageResponseId}`);
  return { data: data.data };
};

export const responseToTask = async (value) => {
  const data = await api.post(
    `/${PREFIX}/response-to-task/${value.imageResponseId}`,
    value,
  );
  return { data: data.data };
};

export const generateNewTask = async (value) => {
  const data = await api.post(
    `/${PREFIX}/generate-new-task/${value.imageResponseId}`,
    value,
  );
  return { data: data.data };
};
