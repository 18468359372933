import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import styled from "styled-components";
import Hands from "./customer/Hands";
import { Link } from "react-router-dom";
import RefreshIcon from "@/common/icons/refresh-icon.svg";
import { useLanguageData } from "../hooks/useLanguageData";

const TitleLessonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  width: 98%;
  border-bottom: 1px solid #d9d9d9;
  padding: 5px;

  @media (max-width: 600px) {
    display: none;
  }
`;

const RefreshButton = styled.button`
  display: inline-flex;
  margin-left: 10px;
  padding: 0;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
  background: unset;
  text-align: center;
  font-family: Work Sans;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.8px;
  text-transform: uppercase;
  border: none;
`;

const TitleLessonWrapperMobile = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  background: ${(props) => props.background};
  padding-top: 10px;

  @media (min-width: 600px) {
    display: none;
  }
`;

const BackWrapper = styled(Link)`
  display: flex;
  align-items: center;
  min-width: 200px;
  gap: 5px;
  color: #868686;
  text-decoration: none;

  @media (max-width: 600px) {
    min-width: 10px;
    margin-left: 20px;
    margin-right: -20px;
    color: rgba(5, 39, 93, 1);
  }
`;

const TextBack = styled.p`
  margin: 0;
  text-decoration: none;
  font-family: Work Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 123.5%;
  letter-spacing: 0.4px;

  @media (max-width: 600px) {
    display: none;
  }
`;
const Text = styled.h3`
  color: #29185e;

  font-family: Work Sans;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 123.5%;
  letter-spacing: 0.48px;
  margin: 0;

  @media (max-width: 600px) {
    color: #29185e;
    margin-bottom: 10px;
  }
`;

const EmptyContainer = styled.div`
  width: 200px;

  @media (max-width: 600px) {
    width: 10px;
  }
`;

const TopTitleHeader = ({
  title = "",
  source = "",
  forWhat = "",
  navigate = "",
  showHands = false,
  refresh = false,
  refreshFunction,
  background,
}) => {
  const { back } = useLanguageData("features.topbar");

  return (
    <>
      <TitleLessonWrapper>
        <BackWrapper to={navigate}>
          <FontAwesomeIcon icon={faChevronLeft} style={{ fontSize: "12px" }} />
          <TextBack>{back}</TextBack>
        </BackWrapper>
        <Text>
          {title}
          {refresh && (
            <RefreshButton onClick={refreshFunction}>
              <img src={RefreshIcon} />
            </RefreshButton>
          )}
        </Text>
        {showHands ? (
          <Hands type={source} forWhat={forWhat} />
        ) : (
          <EmptyContainer />
        )}
      </TitleLessonWrapper>
      <TitleLessonWrapperMobile background={background}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
            marginBottom: "-10px",
          }}
        >
          <BackWrapper to={navigate}>
            <FontAwesomeIcon icon={faChevronLeft} />
          </BackWrapper>
          <Text>
            {title}{" "}
            {refresh && (
              <RefreshButton onClick={refreshFunction}>
                <img src={RefreshIcon} />
              </RefreshButton>
            )}
          </Text>
          <div style={{ width: "10px" }}></div>
        </div>
        {showHands ? (
          <Hands type={source} forWhat={forWhat} />
        ) : (
          <EmptyContainer />
        )}
      </TitleLessonWrapperMobile>
    </>
  );
};

export default TopTitleHeader;
