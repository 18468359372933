import { MESSAGE_DATA_REDUCER_ACTION } from './messageDataActions';

export const messageDataWrapHandlers = (dispatch) => ({
  setConversationWithChatGPT: (value) =>
    dispatch({
      type: MESSAGE_DATA_REDUCER_ACTION.CONVERSATION_WITH_CHAT_GPT,
      value,
    }),
  setCurrent: (value) =>
    dispatch({
      type: MESSAGE_DATA_REDUCER_ACTION.CURRENT,
      value,
    }),

  removeShowMessagesTranslations: (value) =>
    dispatch({
      type: MESSAGE_DATA_REDUCER_ACTION.REMOVE_SHOW_MESSAGES_TRANSLATIONS,
      value,
    }),
  addShowMessagesTranslations: (value) =>
    dispatch({
      type: MESSAGE_DATA_REDUCER_ACTION.ADD_SHOW_MESSAGES_TRANSLATIONS,
      value,
    }),
  setTrnaslationMessages: (value) =>
    dispatch({
      type: MESSAGE_DATA_REDUCER_ACTION.TRANSLATION_MESSAGES,
      value,
    }),
  addLoadingTranslations: (value) =>
    dispatch({
      type: MESSAGE_DATA_REDUCER_ACTION.ADD_LOADING_TRANSLATIONS,
      value,
    }),
  removeLoadingTranslations: (value) =>
    dispatch({
      type: MESSAGE_DATA_REDUCER_ACTION.REMOVE_LOADING_TRANSLATIONS,
      value,
    }),
  setLoadingVoice: (value) =>
    dispatch({
      type: MESSAGE_DATA_REDUCER_ACTION.LOADING_VOICE,
      value,
    }),
  setRecordings: (value) =>
    dispatch({
      type: MESSAGE_DATA_REDUCER_ACTION.RECORDINGS,
      value,
    }),
  setVoiceTalking: (value) =>
    dispatch({
      type: MESSAGE_DATA_REDUCER_ACTION.VOICE_TALKING,
      value,
    }),

  setIsHintLoading: (value) =>
    dispatch({
      type: MESSAGE_DATA_REDUCER_ACTION.IS_HINT_LOADING,
      value,
    }),

  setHintData: (value) =>
    dispatch({
      type: MESSAGE_DATA_REDUCER_ACTION.HINT_DATA,
      value,
    }),

  setMessageScore: (value) =>
    dispatch({
      type: MESSAGE_DATA_REDUCER_ACTION.MESSAGE_SCORE,
      value,
    }),

  setShowProblem: (value) =>
    dispatch({
      type: MESSAGE_DATA_REDUCER_ACTION.SHOW_PROBLEM,
      value,
    }),
  setShowBadMessage: (value) =>
    dispatch({
      type: MESSAGE_DATA_REDUCER_ACTION.HANDLE_SHOW_BAD_MESSAGE,
      value,
    }),
});
