import React, { useContext } from 'react';
import { Helmet } from 'react-helmet';
import { useLanguageData } from '../hooks/useLanguageData';
import { LanguageContext } from '../contexts/LanguageContext';

const Seo = () => {
  const common = useLanguageData('common');
  const { lang } = useContext(LanguageContext);

  return (
    <>
      <Helmet
        htmlAttributes={{
          lang,
        }}
      >
        <title>{`SynthiAi`}</title>
        <meta property="description" content={common?.description} />
        <meta property="og:title" content="SynthiAi" />
        <meta property="og:description" content={common?.description} />
      </Helmet>
    </>
  );
};

export default Seo;
