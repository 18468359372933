import React from 'react';
import styled from 'styled-components';
import FilledHeart from '../images/filled-heart.svg';
import { Link } from 'react-router-dom';
import { useLanguageData } from '@/common/hooks/useLanguageData';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 18px;
`;

const Title = styled.div`
  color: #29185e;
  font-family: 'Work Sans';
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%; /* 25.2px */
  text-align: center;
`;

const BoxWrapper = styled.div`
  display: flex;
  justify-content: center;
  overflow: auto;
  scrollbar-color: transparent transparent;
  gap: 34px;
  margin-top: 8px;
`;

const Box = styled(Link)`
  border: 1px solid #c6bce7;
  position: relative;
  width: 150px;
  text-decoration: none;
  border-radius: 8px; /* Make it a circle with rounded corners */
  background-color: white !important;
  box-shadow: 0px 0px 11px 0px rgba(79, 167, 180, 0.2);
`;

const ImageWrapper = styled.div`
  position: relative;
  width: 150px;
  height: 130px;

  overflow: hidden;

  img {
    border-radius: 0px 0px 8px 8px;
    width: 100%;
    height: 100%;
  }
`;

const TopTitle = styled.div`
  display: flex;
  width: 150px;
  padding: 8px 0px 2px 0px;
  justify-content: center;
  align-items: center;
  color: #29185e;
  font-family: 'Work Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 140%; /* 19.6px */
  max-width: 120px;
  margin: 0 auto;
`;

const TopSubtitle = styled.div`
  color: #29185e;
  text-align: center;
  font-family: 'Work Sans';
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 16.8px */
  padding: 0px 0px 4px 0px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const Heart = styled.div`
  position: absolute;
  right: 5px;
  top: 5px;
  width: 25px;
  z-index: 2;

  img {
    width: 100%;
    height: auto;
  }
`;

const FavoriteDesktop = ({
  data = [],
  handleRemoveFromFavorite,
  translation,
}) => {
  const common = useLanguageData('common');

  const getNavigationLink = (id, type) => {
    if (type === 'scenes') {
      return `/learn/scenes/${id}`;
    }

    if (type === 'flashcards') {
      return `/learn/flashcards/flashcard/${id}`;
    }

    if (type === 'words') {
      return `/learn/words/${id}`;
    }
  };

  return (
    <>
      {data.length > 0 && (
        <Wrapper>
          <Title>{translation.yourFavorite}</Title>
          <BoxWrapper>
            {data.slice(0, 5).map((d) => (
              <Box to={getNavigationLink(d._id, d.group)} key={d._id}>
                <TopTitle>{common[d.group]}</TopTitle>
                <TopSubtitle>{d.title}</TopSubtitle>
                <ImageWrapper>
                  <img src={d?.image} />
                  <Heart
                    onClick={(e) => {
                      e?.preventDefault();
                      e?.stopPropagation();
                      handleRemoveFromFavorite(d._id, d.group);
                    }}
                  >
                    <img src={FilledHeart} />
                  </Heart>
                </ImageWrapper>
              </Box>
            ))}
          </BoxWrapper>
        </Wrapper>
      )}
    </>
  );
};

export default FavoriteDesktop;
