import React from 'react';
import styled from 'styled-components';
import animationData from './data/data.json';
import Lottie from 'react-lottie';

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  height: calc(var(--vh, 1vh) * 100);
  width: 100vw;
  background-color: #28064a;
  top: 0;
  left: 0;
  z-index: 999999;
`;

const Loading = () => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    renderer: 'svg',
  };

  return (
    <Wrapper>
      <div>
        <Lottie options={defaultOptions} height={400} width={400} />
      </div>
    </Wrapper>
  );
};

export default Loading;
