export const checkPassword = (pass, passRepeat) => {
  if (pass !== passRepeat)
    return {
      valid: false,
      message: 'passwordError5',
    };

  if (pass.length <= 6)
    return {
      valid: false,
      message: 'passwordError1',
    };

  if (!/[A-Z].*/.test(pass))
    return {
      valid: false,
      message: 'passwordError2',
    };

  if (!/[a-z].*/.test(pass))
    return {
      valid: false,
      message: 'passwordError3',
    };

  if (!/\d/.test(pass))
    return {
      valid: false,
      message: 'passwordError4',
    };

  return { valid: true, message: 'Valid Password' };
};
