import React, { useContext, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import ArrowLeft from './images/arrow-left.svg';
import { useMutation } from 'react-query';
import { loadUserLearningData } from '@/api/query/user';
import Loading from '@/common/components/Loading';
import { useNavigate } from 'react-router-dom';
import { changeUserTutor, getAiPersons } from '@/api/query/ai-person';
import { MessageContext } from '@/common/contexts/message-context/MessageContext';
import MicOn from './images/mic-on.svg';
import { Howl } from 'howler';
import { useLanguageData } from '@/common/hooks/useLanguageData';

const IconTitleWrapper = styled.div`
  width: 12px;
  display: flex;
  align-items: center;
`;

const TopBar = styled.div`
  margin-top: 24px;
  width: 90vw;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (min-width: 600px) {
    margin-left: 20px;
    max-width: 1200px;
  }
`;

const Title = styled.div`
  color: #05275d;
  text-align: center;
  font-family: 'Work Sans';
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.48px;
`;

const Card = styled.div`
  margin-top: 20px;
  border-radius: 16px;
  background: #fff;
  padding: 16px;
  overflow-y: auto;
  height: fit-content;
  max-height: calc(var(--vh, 1vh) * 90 - 160px);
  display: flex;
  flex-direction: column;
  gap: 20px;

  @media (min-width: 600px) {
    flex-direction: row;
    gap: 40px;
    margin: 0 auto;
    flex-wrap: wrap;
  }
`;

const Section = styled.div`
  width: 85vw;
  margin: -10px;
  padding: 10px;
  display: flex;
  justify-content: left;
  align-items: center;

  @media (min-width: 600px) {
    flex-direction: column;
    width: 200px;
    border-radius: 16px;
    border: 1px solid rgba(79, 167, 180, 0.2);
    background: #650675;
    gap: 10px;
    box-shadow: 0px 0px 11px 0px rgba(79, 167, 180, 0.2);
  }
`;

export const LevelOptionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
`;

export const InputWrapper = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  cursor: pointer;

  label {
    cursor: pointer;
    font-weight: ${(props) => (props.isActive ? '600' : 'normal')};
    color: #05275d;
    text-align: center;
    font-family: 'Work Sans';
    font-size: 12px;
    font-style: normal;
    line-height: 140%;
  }

  input {
    margin: 0;
  }
`;

const LanguageName = styled.p`
  color: #05275d;
  font-family: 'Work Sans';
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.64px;
  text-transform: uppercase;
  margin: 0;
  display: flex;
  justify-content: left;
  align-items: center;
  gap: 10px;

  @media (min-width: 600px) {
    text-align: center;
    justify-content: center;
  }
`;

const Subtitle = styled.p`
  color: #05275d;
  text-align: center;
  font-family: 'Work Sans';
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%; /* 16.8px */
  max-width: 300px;

  @media (min-width: 600px) {
    max-width: 600px;
  }
`;

const Description = styled.p`
  color: #05275d;
  font-family: 'Work Sans';
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%; /* 12px */
  margin: 0;
  max-width: 200px;

  @media (min-width: 600px) {
    text-align: center;
  }
`;

const TagTitle = styled.p`
  color: #05275d;
  font-family: 'Work Sans';
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%; /* 14px */
  margin: 0;
`;

const RestInfoWrapper = styled.div`
  margin-left: 15px;

  @media (min-width: 600px) {
    margin-left: 0;
  }
`;

const TagsWrapper = styled.div`
  display: flex;
  gap: 5px;
  margin-top: 10px;

  @media (min-width: 600px) {
    justify-content: center;
  }
`;

const ProfileTutor = () => {
  const bottom = useLanguageData('profile.bottom');

  const [tutors, setTutors] = useState([]);
  const [userData, setUserData] = useState();
  const [speaking, setSpeaking] = useState();

  const navigate = useNavigate();

  const { addMessage } = useContext(MessageContext);

  const loadDataMutation = useMutation({
    mutationFn: () => getAiPersons(),
    onSuccess: ({ data }) => {
      setTutors(data);
    },
  });

  const loadUserMutation = useMutation({
    mutationFn: () => loadUserLearningData(),
    onSuccess: ({ data }) => {
      setUserData(data);
    },
  });

  const changeUserTutorMutation = useMutation({
    mutationFn: (data) => changeUserTutor(data),
    onSuccess: ({ data }) => {
      loadUserMutation.mutate();
      addMessage(bottom.tutroChanged, 'success');
    },
    onError: () => {
      addMessage(bottom.error, 'error');
    },
  });

  const handleChangeTutor = (tutor) => {
    const payload = {
      tutor,
    };

    changeUserTutorMutation.mutate(payload);
  };

  const handlePlay = (speak) => {
    if (speaking) {
      return;
    }
    const prefix = 'data:audio/wav;base64,';
    const audio = new Howl({
      src: [prefix + speak.voice.base64],
      onend: () => {
        setSpeaking(false);
      },
    });
    setSpeaking(true);
    audio?.play();
  };

  useEffect(() => {
    loadDataMutation.mutate();
    loadUserMutation.mutate();
  }, []);

  return (
    <>
      {(loadDataMutation.isLoading ||
        changeUserTutorMutation.isLoading ||
        loadUserMutation.isLoading) && <Loading />}
      <TopBar>
        <IconTitleWrapper>
          <img src={ArrowLeft} onClick={() => navigate('/profile')} />
        </IconTitleWrapper>
        <Title>{bottom.pickNewTutor}</Title>
        <IconTitleWrapper />
      </TopBar>
      <TopBar style={{ justifyContent: 'center', margin: '-10px 0 20px 0' }}>
        <Subtitle>{bottom.yourDecision}</Subtitle>
      </TopBar>
      <Card>
        {tutors.map((l) => (
          <Section
            id={`tutor-${l._id}`}
            onClick={() => handleChangeTutor(l._id)}
            style={{
              background:
                l._id === userData?.speaker?._id ? '#650675' : 'white',
            }}
          >
            <div
              style={{
                width: '80px',
                height: '80px',
                overflow: 'hidden',
                borderRadius: '15px',
              }}
            >
              <img
                src={l.image.base64}
                style={{ width: '100%', height: 'auto' }}
              />
            </div>
            <RestInfoWrapper
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-start',
                height: '80px',
              }}
            >
              <div>
                <LanguageName
                  style={{
                    color:
                      l._id === userData?.speaker?._id ? 'white' : '#05275D',
                  }}
                >
                  {l.name}
                  <div
                    style={{
                      width: '20px',
                      height: '20px',
                      borderRadius: '40px',
                      padding: '5px',
                      background: '#EEF2F5',
                    }}
                  >
                    <img
                      onClick={(e) => {
                        e.stopPropagation();
                        handlePlay(l);
                      }}
                      src={MicOn}
                      style={{ width: '100%', height: 'auto' }}
                    />
                  </div>
                </LanguageName>
              </div>
              <Description
                style={{
                  color: l._id === userData?.speaker?._id ? 'white' : '#05275D',
                  marginTop: '5px',
                }}
              >
                {l.description}
              </Description>
              <TagsWrapper>
                {l.tags.map((t) => (
                  <TagTitle
                    style={{
                      background: t.color,
                      width: 'fit-content',
                      borderRadius: '5px',
                      padding: '2px 5px',
                    }}
                  >
                    {t.tag}
                  </TagTitle>
                ))}
              </TagsWrapper>
            </RestInfoWrapper>
          </Section>
        ))}
      </Card>
    </>
  );
};

export default ProfileTutor;
