import { Howl, Howler } from 'howler';

export const voiceRunner = async (voice, name = '_INIT_', setVoiceTalking) => {
  const blob = new Blob([new Uint8Array(voice)], {
    type: 'audio/mp3',
  });

  const url = await (function blobToBase64(blob) {
    return new Promise((resolve, _) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.readAsDataURL(blob);
    });
  })(blob);

  const audio = new Howl({
    src: [url],
    onpause: () => {
      setVoiceTalking(false);
    },
    onstop: () => {
      setVoiceTalking(false);
    },
    onmute: () => {
      setVoiceTalking(false);
    },
    onfade: () => {
      setVoiceTalking(false);
    },
    onend: () => {
      setVoiceTalking(false);
    },
    onunload: () => {
      setVoiceTalking(false);
    },
  });

  setVoiceTalking(name);
  await audio.play();
};
