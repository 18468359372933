import React, { useEffect, useState } from 'react';
import { DemoConversationContext } from '@/common/contexts/DemoConversation';
import {
  ContentWrapper,
  DemoSectionWrapper,
  MessageBox,
} from './lesson.styles';
import { StartInfo, TalkiMessage } from './components';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';
import { useMutation } from 'react-query';
import { findCustomerLessonById, findLessonById } from '@/api/query/lesson';
import { handleDeleteLessonMessage } from '@/api/query/lead-history';
import NewTopTileHeader from '@/common/components/chat/NewTopTileHeader';
import Synthi from './images/synthiai-chat.svg';
import { SOURCES_TYPES } from '@/common/components/customer/Hands';

const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100vw;
  height: calc(var(--vh, 1vh) * 100 - 60px);
  overflow: hidden;
  text-align: center;
  margin: 0;
  padding: 0;

  @media (min-width: 600px) {
    flex-grow: 1;
    margin: 0;
    width: 100%;
    height: fit-content;
    position: relative;
  }
`;

const DemoScript = ({ setPageNumber }) => {
  const { id } = useParams();
  const [lesson, setLesson] = useState({});
  const [data, setData] = useState({});
  const [showButtoReccord, setShowButtoReccord] = useState(false);
  const [showLoading, setShowLoading] = useState();
  const [showText2, setShowText2] = useState(true);
  const [showStarInfo, setShowStarInfo] = useState(false);

  const handleLoadLessonMutation = useMutation({
    mutationFn: (value) => findCustomerLessonById(value),
    onSuccess: ({ data }) => {
      setLesson(data);
    },
  });

  const handleDeleteLessonMessageMutation = useMutation({
    mutationFn: (value) => handleDeleteLessonMessage(value),
    onSuccess: ({ data }) => {
      window.location.reload(true);
    },
  });

  const handleClearConversation = () => {
    const payload = {};
    payload.lessonId = lesson._id;

    handleDeleteLessonMessageMutation.mutate(payload);
  };

  useEffect(() => {
    handleLoadLessonMutation.mutate(id);
  }, [id]);

  useEffect(() => {
    window.document.title = `Synthi Ai - scenka`;
  }, []);

  return (
    <>
      <PageWrapper>
        <NewTopTileHeader
          image={Synthi}
          name={lesson.name}
          backTo={'/learn/scenes'}
          refresh={true}
          forWhat={lesson._id}
          refreshFunction={handleClearConversation}
          source={SOURCES_TYPES.SCENE}
        />
        <DemoSectionWrapper>
          <ContentWrapper>
            <DemoConversationContext.Provider
              value={{
                data,
                setData,
                showButtoReccord,
                setShowButtoReccord,
                showLoading,
                setShowLoading,
                setPageNumber,
                showText2,
                setShowText2,
                lesson,
                showStarInfo,
                setShowStarInfo,
              }}
            >
              <MessageBox>
                <TalkiMessage />
                <div id="bottom-info" />
              </MessageBox>
            </DemoConversationContext.Provider>
          </ContentWrapper>
        </DemoSectionWrapper>
      </PageWrapper>
      {showStarInfo && (
        <StartInfo showStarInfo={showStarInfo} setShow={setShowStarInfo} />
      )}
    </>
  );
};

export default DemoScript;
