import React, { useContext } from 'react';
import Visa from '../icons/Visa blue.svg';
import Master from '../icons/mastercard.svg';
import Blik from '../icons/blik.svg';
import Transfer from '../icons/przelewy.svg';
import TransferCZ from '../icons/bank_cs.svg';
import Payu from '../icons/payou.logo.svg';
import Lock from '../icons/secured-lock.svg';
import Paypal from '../icons/paypal.svg';
import ApplePay from '../icons/applepay.png';
import GooglePay from '../icons/googlepay.svg';
import Stripe from '../icons/stripe.svg';

import { useLanguageData } from '@/common/hooks/useLanguageData';
import styled from 'styled-components';
import { LanguageContext } from '@/common/contexts/LanguageContext';
import {
  PAYU_OPERATOR_COUNTRIES,
  STRIPE_ONE_OFF,
  STRIPE_SUBSCRIBTION,
} from '../Checkout';

const PaymentIcon = styled.img`
  width: 60px;
`;

const ImageWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  max-height: 20px;

  border-radius: 5px;
  border: 1px solid rgba(79, 167, 180, 0.2);
  background: #fff;
  padding: 5px;
`;

const PaymentTitle = styled.p`
  color: #05275d;
  font-family: 'Work Sans';
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.32px;

  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
`;

const IMAGES = [
  {
    image: Blik,
    width: '30px',
    langs: ['pl'],
  },
  {
    image: Visa,
    width: '30px',
    langs: [
      'pl',
      'cs',
      'es',
      'bg',
      'de',
      'es',
      'et',
      'hr',
      'it',
      'ro',
      'sk',
      'hu',
      'el',
      'sl',
      'id',
      'sv',
    ],
  },
  {
    image: Master,
    width: '20px',
    langs: [
      'pl',
      'cs',
      'es',
      'bg',
      'de',
      'es',
      'et',
      'hr',
      'it',
      'ro',
      'sk',
      'el',
      'hu',
      'en',
      'sl',
      'id',
      'sv',
    ],
  },
  {
    image: Transfer,
    width: '45px',
    langs: ['pl'],
  },
  {
    image: TransferCZ,
    width: '50px',
    langs: [''],
  },
  {
    image: ApplePay,
    width: '30px',
    langs: [
      'es',
      'bg',
      'de',
      'es',
      'et',
      'hr',
      'it',
      'ro',
      'sk',
      'cs',
      'el',
      'en',
      'sl',
      'id',
      'sv',
    ],
  },
  {
    image: GooglePay,
    width: '50px',
    langs: [
      'es',
      'bg',
      'de',
      'es',
      'et',
      'hr',
      'it',
      'ro',
      'sk',
      'cs',
      'el',
      'en',
      'sl',
      'id',
      'sv',
    ],
  },
  {
    image: Paypal,
    width: '50px',
    langs: ['es', 'de', 'et', 'hr', 'it', 'cs', 'el', 'en', 'sl', 'sv'],
  },
];

const SavePayment = () => {
  const { lang } = useContext(LanguageContext);
  const translations = useLanguageData('checkout');

  return (
    <>
      <PaymentTitle>
        <img src={Lock} /> {translations.savePayments}
        {PAYU_OPERATOR_COUNTRIES.includes(lang) && (
          <PaymentIcon src={Payu} style={{ width: '45px' }} />
        )}
        {[...STRIPE_SUBSCRIBTION, ...STRIPE_ONE_OFF].includes(lang) && (
          <PaymentIcon src={Stripe} style={{ width: '45px' }} />
        )}
      </PaymentTitle>
      <ImageWrapper style={{ marginTop: '-15px' }}>
        {IMAGES.filter((i) => i.langs.includes(lang)).map((i) => (
          <PaymentIcon src={i.image} style={{ width: i.width }} />
        ))}
      </ImageWrapper>
    </>
  );
};

export default SavePayment;
