import React, { useEffect, useLayoutEffect, useRef } from 'react';
import styled from 'styled-components';
import ArrLeft from './icons/arr-left-white.svg';
import { SOURCES_TYPES } from '../customer/Hands';
import NewHands from '../customer/NewHands';
import { Link } from 'react-router-dom';
import RefreshIcon from './icons/refreshi-icon-grey.svg';
import VolumeMute from './icons/volume-mute.svg';
import VolumeUnMute from './icons/volume-unmute.svg';
import Tutorial from '@/assets/images/dailyTutorial/samouczek.svg';

import { Howler } from 'howler';

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 56px;
  width: 100%;
  background: linear-gradient(271deg, #4322c6 -0.67%, #48068a 101.48%);
  box-sizing: border-box;
  padding: 0 8px;
`;

const ImageWrapper = styled.div`
  width: 44px;
  overflow: hidden;
  margin-left: 10px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  img {
    width: 100%;
    height: auto;
  }
`;

const BackIconWrapper = styled(Link)`
  width: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;

  img {
    width: 100%;
    height: auto;
  }

  @media (min-width: 600px) {
    margin-right: ${(props) => (props.setShowPopUpTutorial ? '80px' : '0px')};
  }
`;

const LeftWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const NameWrapper = styled.div`
  color: #fff;
  font-family: 'Work Sans';
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  max-width: 150px;
  line-height: 100%; /* 16px */
  letter-spacing: 0.32px;
  text-align: left;
  margin-left: 8px;
`;

const RefreshWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 10px;

  color: #ded4df;
  width: 16px;

  img {
    width: 100%;
    height: auto;
  }
`;

const DesktopWrapper = styled.div`
  display: none;

  @media (min-width: 600px) {
    display: flex;
    align-items: center;
  }
`;

const MobileWrapper = styled.div`
  display: none;

  @media (max-width: 600px) {
    display: flex;
    align-items: center;
  }
`;

const ContentDisplay = ({
  image,
  name,
  showVoiceMute,
  setMuteVoice,
  muteVoice,
  refreshFunction,
  refresh,
}) => {
  return (
    <>
      <ImageWrapper>
        <img src={image} />
      </ImageWrapper>
      <NameWrapper>{name}</NameWrapper>
      {showVoiceMute && (
        <ImageWrapper
          onClick={() => {
            Howler.unload();
            setMuteVoice((prev) => {
              localStorage.setItem('daily_mute', !prev ? 1 : 0);
              return !prev;
            });
          }}
          style={{ width: '20px' }}
        >
          <img
            src={muteVoice ? VolumeMute : VolumeUnMute}
            style={{
              height: '20px',
            }}
          />
        </ImageWrapper>
      )}
      {refresh && (
        <RefreshWrapper onClick={refreshFunction}>
          <img src={RefreshIcon} />
        </RefreshWrapper>
      )}
    </>
  );
};

const NewTopTileHeader = ({
  image,
  name,
  backTo,
  forWhat,
  source,
  refresh,
  refreshFunction,
  muteVoice,
  setMuteVoice,
  showVoiceMute,
  centerDesktop,
  setShowPopUpTutorial,
}) => {
  return (
    <Wrapper>
      <LeftWrapper>
        <BackIconWrapper
          to={backTo}
          setShowPopUpTutorial={setShowPopUpTutorial}
        >
          <img src={ArrLeft} />
        </BackIconWrapper>
        {centerDesktop ? (
          <MobileWrapper>
            <ContentDisplay
              image={image}
              name={name}
              showVoiceMute={showVoiceMute}
              setMuteVoice={setMuteVoice}
              muteVoice={muteVoice}
              refreshFunction={refreshFunction}
              refresh={refresh}
            />
          </MobileWrapper>
        ) : (
          <>
            <ContentDisplay
              image={image}
              name={name}
              showVoiceMute={showVoiceMute}
              setMuteVoice={setMuteVoice}
              muteVoice={muteVoice}
              refreshFunction={refreshFunction}
              refresh={refresh}
            />
          </>
        )}
      </LeftWrapper>
      {centerDesktop && (
        <DesktopWrapper>
          <ContentDisplay
            image={image}
            name={name}
            showVoiceMute={showVoiceMute}
            setMuteVoice={setMuteVoice}
            muteVoice={muteVoice}
            refreshFunction={refreshFunction}
            refresh={refresh}
          />
        </DesktopWrapper>
      )}
      <div style={{ display: 'flex', gap: '15px', marginRight: '10px' }}>
        <NewHands type={source || SOURCES_TYPES.DAILY} forWhat={forWhat} />
        {setShowPopUpTutorial && (
          <ImageWrapper
            onClick={() => {
              setShowPopUpTutorial(() => true);
            }}
            style={{ width: '20px', borderRadius: '0' }}
          >
            <img
              src={Tutorial}
              style={{
                height: '20px',
              }}
            />
          </ImageWrapper>
        )}
      </div>
    </Wrapper>
  );
};

export default NewTopTileHeader;
