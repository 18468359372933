import React, { useContext } from 'react';
import { GoogleLogin, GoogleOAuthProvider } from '@react-oauth/google';
import { useLanguageData } from '../hooks/useLanguageData';
import { MessageContext } from '@/common/contexts/message-context/MessageContext';

const GoogleAuth = ({ handleMutation }) => {
  const clientId = process.env.REACT_APP_GOOGLE_IDENTITY;

  const data = useLanguageData('suggestConfig');
  const { addMessage } = useContext(MessageContext);

  return (
    <>
      <GoogleOAuthProvider clientId={clientId}>
        <GoogleLogin
          onSuccess={handleMutation}
          onError={() => {
            addMessage(data.error, 'error');
          }}
          width={'280px'}
          shape={'pill'}
        />
      </GoogleOAuthProvider>
    </>
  );
};

export default GoogleAuth;
