import React, { useState } from "react";
import styled from "styled-components";
import ArrowLeft from "./images/arrow-left.svg";
import ArrowDown from "../images/arrow-down.svg";
import ArrowUp from "./images/arrow-up.svg";
import { useNavigate } from "react-router-dom";
import { useLanguageData } from "@/common/hooks/useLanguageData";

const IconTitleWrapper = styled.div`
  width: 12px;
  display: flex;
  align-items: center;
`;

const TopBar = styled.div`
  margin-top: 24px;
  width: 90vw;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (min-width: 600px) {
    margin-left: 15px;
    max-width: 1200px;
  }
`;

const Title = styled.div`
  color: #05275d;
  text-align: center;
  font-family: "Work Sans";
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.48px;
`;

const Card = styled.div`
  width: 80vw;
  border-radius: 16px;
  background: #fff;
  padding: 16px;

  @media (min-width: 600px) {
    width: 600px;
    border: 1px solid #d9d9d9;
    margin: 0 auto;
  }
`;

export const LevelOptionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
`;

export const InputWrapper = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  cursor: pointer;

  label {
    cursor: pointer;
    font-weight: ${(props) => (props.isActive ? "600" : "normal")};
    color: #05275d;
    text-align: center;
    font-family: "Work Sans";
    font-size: 12px;
    font-style: normal;
    line-height: 140%;
  }

  input {
    margin: 0;
  }
`;

const Subtitle = styled.p`
  color: #05275d;
  text-align: center;
  font-family: "Work Sans";
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%; /* 16.8px */
  max-width: 300px;

  @media (min-width: 600px) {
    max-width: 600px;
  }
`;

const Question = styled.p`
  display: flex;
  justify-content: space-between;
  color: #05275d;
  font-family: "Work Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 123.5%; /* 17.29px */
  letter-spacing: 0.28px;

  margin: 0;
`;

const Answer = styled.p`
  color: #05275d;
  white-space: pre-wrap;
  font-family: "Work Sans";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 123.5%; /* 14.82px */
  letter-spacing: 0.24px;
  margin: 15px 0 0 0;
`;

const CardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  overflow-y: auto;
  height: calc(var(--vh, 1vh) * 100 - 200px);
`;

const ProfileHelp = () => {
  const [showQuestion, setShowQuestion] = useState();
  const bottom = useLanguageData("profile.bottom");
  const navigate = useNavigate();

  return (
    <>
      <TopBar>
        <IconTitleWrapper>
          <img
            src={ArrowLeft}
            onClick={() => navigate("/profile")}
            style={{ cursor: "pointer" }}
          />
        </IconTitleWrapper>
        <Title>{bottom.faq}</Title>
        <IconTitleWrapper />
      </TopBar>
      <TopBar style={{ justifyContent: "center", margin: "-10px 0 20px 0" }}>
        <Subtitle>
          {bottom.withProblems} <u>{bottom.contactMail}</u>
        </Subtitle>
      </TopBar>
      <CardWrapper>
        {bottom.faqConfig.map((l) => (
          <Card
            id={`language-${l.id}`}
            onClick={() =>
              setShowQuestion((prev) => (prev?.id === l.id ? null : l))
            }
            style={{}}
          >
            <Question>
              {l.question}
              <img
                src={showQuestion?.id === l.id ? ArrowUp : ArrowDown}
                style={{
                  cursor: "pointer",
                }}
              />
            </Question>
            {showQuestion?.id === l.id && <Answer>{l.answer}</Answer>}
          </Card>
        ))}
      </CardWrapper>
    </>
  );
};

export default ProfileHelp;
