import React from "react";
import styled from "styled-components";
import LeftIcon from "@/common/images/group.png";
import { useNavigate } from "react-router-dom";
import Hands, { SOURCES_TYPES } from "@/common/components/customer/Hands";
import TopTitleHeader from "@/common/components/TopTitleHeader";
import { useLanguageData } from "@/common/hooks/useLanguageData";

const Left = styled.p`
  color: #868686;
  min-width: 280px;

  font-family: Work Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 123.5%;
  letter-spacing: 0.4px;
  display: flex;
  gap: 10px;
  align-items: center;
  @media (max-width: 600px) {
    display: none;
  }
`;

const Title = styled.h2`
  color: #650675;

  font-family: Work Sans;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 123.5%;
  letter-spacing: 0.48px;

  @media (max-width: 600px) {
    margin: 0;
  }
`;

const TopWrapper = styled.div`
  border-bottom: 1px solid #d9d9d9;
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 600px) {
    flex-direction: column;
    border: none;
  }
`;

const FlashcardView = ({ data, background, newImage }) => {
  const { title } = useLanguageData("features.fotoMode.welcome");

  return (
    <>
      <TopWrapper>
        <TopTitleHeader
          title={title}
          navigate={`/learn`}
          source={SOURCES_TYPES.FOTO_MODE}
          showHands={true}
          background={background}
          newImage={newImage}
        />
      </TopWrapper>
    </>
  );
};

export default FlashcardView;
