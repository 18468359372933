import React, { useState } from 'react';
import Welcome from './Welcome';
import styled from 'styled-components';
import PickTutor from './PickTutor';
import EnglishLevel from './EnglishLevel';
import Hobby from './Hobby';
import DailyMinutes from './DailyMinutes';
import { useMutation } from 'react-query';
import { updateUserFirstView } from '@/api/query/customer';
import userManager from '@/api/userManager';
import PickLanguage from './PickLanguage';

const Wrapper = styled.div`
  width: 340px;
  height: 600px;
  min-height: 600px;
  max-height: 600px;
`;

const CONFIG = {
  0: (data, setPage, setData, handleSend) => (
    <Welcome data={data} setPage={setPage} />
  ),
  1: (data, setPage, setData) => (
    <PickLanguage data={data} setPage={setPage} setData={setData} />
  ),
  2: (data, setPage, setData) => (
    <PickTutor data={data} setPage={setPage} setData={setData} />
  ),
  3: (data, setPage, setData, handleSend) => (
    <EnglishLevel data={data} setPage={setPage} setData={setData} />
  ),
  4: (data, setPage, setData, handleSend) => (
    <Hobby data={data} setPage={setPage} setData={setData} />
  ),
  5: (data, setPage, setData, handleSend) => (
    <DailyMinutes
      data={data}
      setPage={setPage}
      setData={setData}
      handleSend={handleSend}
    />
  ),
};

const Desktop = ({ handleSend }) => {
  const [page, setPage] = useState(0);
  const [data, setData] = useState({});

  return <>{CONFIG[page](data, setPage, setData, handleSend)}</>;
};

export default Desktop;
