import { styled } from 'styled-components';

export const ContentTranslationWrapper = styled.div`
  display: flex;
  justify-content: left;
  align-items: center;
  text-align: left;
  gap: 10px;
  margin-top: 10px;
`;
