import React from "react";
import styled from "styled-components";
import Fotozagadka from "../images/fotozagadka.png";
import { Link } from "react-router-dom";
import { useLanguageData } from "@/common/hooks/useLanguageData";

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100vw;
  margin-top: 20px;
`;

const Box = styled(Link)`
  position: relative;
  background-image: url(${(props) => props.image});
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 140px;
  flex-direction: column;
  width: 90vw;
  margin-left: 5vw;
  gap: 14px;
  margin-top: 12px;
  border-radius: 8px;
  overflow: hidden;
  text-decoration: none;
`;

const Subtitle = styled(Link)`
  color: #29185e;
  font-family: "Work Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 123.5%; /* 17.29px */
  letter-spacing: 0.28px;
  text-align: left;
  margin-left: 5vw;
  z-index: 1;
  text-decoration: none;
`;

const TitleTop = styled(Link)`
  color: #29185e;
  font-family: "Work Sans";
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%; /* 25.2px */
  text-align: left;
  width: 360px;
  margin-left: 5vw;
  margin-bottom: -3px;
  text-decoration: none;
`;

const ImageMode = () => {
  const trasnaltion = useLanguageData("learn.fotoMode");

  return (
    <Wrapper>
      <TitleTop to={"/learn/foto-mode"}>{trasnaltion.title}</TitleTop>
      <Subtitle to={"/learn/foto-mode"}>{trasnaltion.subtitle}</Subtitle>
      <Box image={Fotozagadka} to={"/learn/foto-mode"}></Box>
    </Wrapper>
  );
};

export default ImageMode;
