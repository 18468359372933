import Api from '../api';
const { axios: api } = Api;
const PREFIX = 'ai-person';

export const getAiPersons = async (payload) => {
  const data = await api.get(`/${PREFIX}`, payload);
  return { data: data.data };
};

export const getCustomerAiPerson = async () => {
  const data = await api.get(`/${PREFIX}/customer`);
  return { data: data.data };
};

export const changeUserTutor = async (payload) => {
  const data = await api.patch(`/${PREFIX}/profile-tutor`, payload);
  return { data: data.data };
};
