import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useMutation } from 'react-query';
import Loading from '@/common/components/Loading';
import {
  findCustomer,
  loadMonthSteak,
  loadWords,
  unsubscribe,
  updateCustomerImage,
} from '@/api/query/customer';
import IconLogin from '@/common/images/icon-login.svg';
import { Link, useNavigate } from 'react-router-dom';
import userManager from '@/api/userManager';
import api from '@/api/api';
import { langMoment } from '@/common/components/LanugangeMomement';

import {
  Card,
  ImageWrapper,
  Logout,
  LogoutWrapper,
  MainDataWrapper,
  UserDataWrapper,
  UserNameWrapper,
  Wrapper,
} from './profile.styles';
import styled from 'styled-components';
import CancelPopUp from './components/CancelPopUp';
import 'react-input-range/lib/css/index.css';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { getNavigationPath } from '@/common/navigation/getNavigationPath';
import editProfileImage from './images/profile.svg';
import ChangeName from './components/ChangeName';
import ChangePasswordComponent from './components/ChangePasswordComponent';
import ArrowRight from './images/arrow-right.svg';
import ArrowDown from './images/arrow-down.svg';
import ArrowUp from './subpages/images/arrow-up.svg';
import ProfileChange from './components/ProfileChange';
import Sreak from './images/sreak-full.svg';
import Fire from './images/fire.svg';
import SreakDefault from './images/streak-default.svg';
import SreakCalendar from './images/streak-calendar.svg';
import SreakNo from './images/streak-no.svg';
import Clock from './images/clock.svg';
import GrowUp from './images/grow-up.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import WriteOpinion, { OPINION_OPTIONS } from './components/WriteOpinion';
import ArrLeft from './images/streak-words/arr-left.svg';
import ArrRight from './images/streak-words/arr-right.svg';
import { useLanguageData } from '@/common/hooks/useLanguageData';
import { LanguageContext } from '@/common/contexts/LanguageContext';
import { generateLinkToProductPage } from '@/common/functions/generateLinkToProductPage';

const { tokenManager } = api;

const ChangePassword = styled.a`
  color: #05275d;
  text-align: center;
  font-family: 'Work Sans';
  font-size: 14px;
  font-style: normal;
  cursor: pointer;
  font-weight: 400;
  line-height: 123.5%; /* 17.29px */
  letter-spacing: 0.28px;
  text-decoration-line: underline;
  margin: 0;
`;

const EmialWrapper = styled.div`
  color: #7f7c85;
  font-family: 'Work Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 110%; /* 15.4px */
  margin-top: -5px;
  margin-left: 25px;
`;

const Section = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 10px;

  @media (min-width: 600px) {
    width: 340px;
  }
`;

const SectionTittle = styled.p`
  color: #05275d;
  font-family: 'Work Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 123.5%; /* 17.29px */
  letter-spacing: 0.28px;
  margin: 0;
`;

const Border = styled.div`
  border-bottom: 1px solid #ece5f2;
`;

const ImageWrapperMenu = styled.div`
  width: fit-content;
  display: flex;
  align-items: center;
  gap: 10px;

  img {
    width: 100%;
    height: auto;
  }

  color: #05275d;
  text-align: right;
  font-family: 'Work Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 123.5%; /* 17.29px */
  letter-spacing: 0.28px;
`;

const FlexOne = styled.div`
  @media (min-width: 600px) {
    display: none;
  }
`;

const TitleSection = styled.div`
  color: #29185e;
  text-align: center;
  font-family: 'Work Sans';
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 110%; /* 15.4px */

  display: flex;
  justify-content: space-between;
  align-content: center;
`;

const BoxWrapper = styled.div`
  display: flex;
  justify-content: center;
`;
const Box = styled.div`
  width: 120px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
const ImageBoxWrapper = styled.div`
  width: 40px;
  height: 40px;
  overflow: hidden;

  img {
    width: auto;
    height: 100%;
  }
`;
const TitleBox = styled.div`
  color: #29185e;
  font-family: 'Work Sans';
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 110%;
  margin-top: 4px;
`;
const SubTitleBox = styled.div`
  color: #7f7c85;
  text-align: center;
  font-family: 'Work Sans';
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 110%;
  margin-top: 4px;
`;

const ButtonChange = styled.div`
  display: inline-flex;
  padding: 5px 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 41px;
  background: #ece5f2;
  color: #29185e;
  font-family: 'Work Sans';
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 123.5%;
  letter-spacing: 0.2px;
`;

const TitleSpeaker = styled.div`
  color: #29185e;
  font-family: 'Work Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 123.5%;
  letter-spacing: 0.28px;
`;

const SubTitleSpeaker = styled.div`
  color: #7f7c85;
  font-family: 'Work Sans';
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%; /* 12px */
`;

const TutorWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  justify-content: center;
`;

const TutorImageWrapper = styled.div`
  width: 100px;

  img {
    width: 100%;
    height: auto;
  }
`;

const StatsWrapperDate = styled.div`
  display: flex;
  gap: 24px;
  width: 80vw;
  max-width: 320px;
  box-sizing: border-box;
  padding: 0 20px;
  height: 120px;
  border-radius: 6px;
  background: #f4f6f8;
  margin: 0 auto;

  @media (min-width: 600px) {
    width: 320px;
  }

  @media (max-width: 400px) {
    width: 95vw;
  }
`;

const ChartStatsTitle = styled.div`
  color: #29185e;
  text-align: center;
  font-family: 'Work Sans';
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 110%; /* 11px */
`;

const ChartStatsSubtitle = styled.div`
  color: #7f7c85;
  font-family: 'Work Sans';
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 110%; /* 13.2px */
`;

const Height = styled.div`
  width: 18px;
  height: ${(props) => `${Math.min(props.height, 80)}px`};
  flex-shrink: 0;
  border-radius: 15px 15px 0px 0px;
  background: #770baf;
`;

const SubtitleForStreak = styled.div`
  color: #29185e;
  text-align: center;
  font-family: 'Work Sans';
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 139%; /* 16.68px */
  letter-spacing: 0.24px;
  margin-top: -10px;
`;

const ChartWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  gap: 16px;
`;

const StyledLink = styled(Link)`
  color: #7f7c85;
  font-family: 'Work Sans';
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 123.5%; /* 14.82px */
  letter-spacing: 0.24px;
`;

const WrapperTerms = styled.div`
  color: #7f7c85;
  font-family: 'Work Sans';
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 123.5%; /* 14.82px */
  letter-spacing: 0.24px;
`;

const BackgroundGrey = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 8px;

  @media (min-width: 600px) {
    min-width: 840px;
    padding: 20px;
    background: #f4f6f8;
  }
`;

const BoxWrapperDesktop = styled.div`
  @media (min-width: 600px) {
    display: flex;
    justify-content: center;
    gap: 86px;
  }
`;

const FromToStyle = styled.div`
  color: #29185e;
  font-family: 'Work Sans';
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 110%; /* 13.2px */
  display: flex;
  align-items: center;
`;

const SECTIONS = {
  SUBSCRIPTION: 'subscription',
};

const DayTopElement = styled.div`
  width: 48px;
  height: 32px;
  color: #29185e;
  font-family: Lato;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const DayElement = styled.div`
  display: flex;
  width: 48px;
  height: 48px;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  color: #29185e;
  font-family: Lato;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

const TodayPoint = styled.div`
  position: absolute;
  bottom: -5px;
  width: 18px;
  left: 3px;
  height: 2px;
  flex-shrink: 0;
  border-radius: 35px;
  background: #650675;
`;

const NumberWrapper = styled.div`
  position: absolute;
  top: 35%;

  color: #fff;
  font-family: Lato;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

const IconWrapperArr = styled.div`
  width: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const DaysWrapper = styled.div`
  max-width: 350px;
  @media (min-width: 600px) {
    border-radius: 8px;
    border: 1px solid #ece5f2;
    margin-top: 12px;
  }
`;

const Profile = () => {
  const { days, top, middle, bottom, rules, privacy, span } =
    useLanguageData('profile');
  const { lang } = useContext(LanguageContext);
  const common = useLanguageData('common');

  const navigate = useNavigate();
  const [showSection, setShowSection] = useState();
  const [showOpinion, setShowOpinion] = useState();
  const [showCancelPopup, setShowCancelPopup] = useState();
  const [user, setUser] = useState({});
  const [changeName, setChangeName] = useState();
  const [changePasswordValue, setChangePasswordValue] = useState();
  const [profileChange, setProfileChange] = useState();
  const [showCalendar, setShowCalendar] = useState();

  const loadDataMutation = useMutation({
    mutationFn: () => {
      return findCustomer(lang);
    },
    onSuccess: ({ data }) => {
      setUser(data);
    },
  });

  const unsubscribeMutation = useMutation({
    mutationFn: () => unsubscribe(),
    onSuccess: ({ data }) => {
      loadDataMutation.mutate();
      setShowCancelPopup(null);
    },
  });

  const updateUserImageMutation = useMutation({
    mutationFn: (value) => updateCustomerImage(value),
    onSuccess: ({ data }) => {
      setUser(() => data);
    },
  });

  const loadWordsMutation = useMutation({
    mutationFn: (value) => loadWords(value),
    onSuccess: ({ data }) => {
      setUser((prev) => ({
        ...prev,
        stats: { ...data, total: prev.stats.total },
      }));
    },
  });

  const handleOtherWords = (direction) => {
    const payload = {
      from:
        direction === 'left'
          ? langMoment(lang)(user.stats.from)
              .subtract('7', 'days')
              .format('YYYY-MM-DD')
          : langMoment(lang)(user.stats.from)
              .add('7', 'days')
              .format('YYYY-MM-DD'),
      to:
        direction === 'left'
          ? langMoment(lang)(user.stats.to)
              .subtract('7', 'days')
              .format('YYYY-MM-DD')
          : langMoment(lang)(user.stats.to)
              .add('7', 'days')
              .format('YYYY-MM-DD'),
      lang,
    };

    loadWordsMutation.mutate(payload);
  };

  const handleLogout = () => {
    tokenManager.removeToken();
    userManager.removeUser();
    navigate(getNavigationPath('/login'));
  };

  const getStreakImage = (
    element,
    index,
    streak = {},
    showEmpty = false,
    showFire = false,
  ) => {
    const recordFrom = langMoment(lang)(streak?.recordDate)
      .subtract('1', 'day')
      .startOf('day');
    const recordTo = langMoment(lang)(streak?.recordDate)
      .add(Math.max(streak?.maxStreak - 1, 0), 'days')
      .endOf('day');

    const elementDate = langMoment(lang)(element?.fullDate).startOf('day');

    if (recordFrom.isBefore(elementDate) && recordTo.isAfter(elementDate)) {
      return showFire ? SreakCalendar : Sreak;
    }

    if (element?.status) {
      return showFire ? Fire : SreakDefault;
    }

    if (showEmpty) {
      return null;
    }

    return SreakNo;
  };

  useEffect(() => {
    if (!profileChange) {
      loadDataMutation.mutate();
    }
  }, [profileChange]);

  useEffect(() => {
    langMoment(lang).locale('pl');
    window.document.title = 'Synthi Ai - profil';
  }, []);

  const getDiff = (date) => {
    const now = langMoment(lang)();
    const to = langMoment(lang)(date);

    return Math.max(to.diff(now, 'days'));
  };

  const getNumWeeksForMonth = (year, month) => {
    let firstOfMonth = new Date(year, month - 1, 1);
    let day = firstOfMonth.getDay() || 6;
    day = day === 1 ? 0 : day;
    if (day) {
      day--;
    }
    let diff = 7 - day;
    let lastOfMonth = new Date(year, month, 0);
    let lastDate = lastOfMonth.getDate();
    if (lastOfMonth.getDay() === 1) {
      diff--;
    }
    let result = Math.ceil((lastDate - diff) / 7);
    return result + 1;
  };

  const getProperItem = (day, j, skip = false) => {
    if (!skip) {
      let dayInWeek = day.day() === 0 ? 7 : day.day();
      day.add(j <= dayInWeek ? 0 : '1', 'day').format('DD');
    } else {
      day.add('1', 'day').format('DD');
    }

    const toCheck = user?.streak?.monthStreaks?.find(
      (ms) =>
        langMoment(lang)(ms.fullDate).format('DD/MM') === day.format('DD/MM'),
    );

    let image = null;

    if (toCheck) {
      image = getStreakImage(toCheck, 1, user?.streak, true, true);
    }

    let showSth =
      langMoment(lang)().format('DD/MM/YYYY') === day.format('DD/MM/YYYY');

    if (image) {
      return (
        <div
          style={{
            position: 'relative',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <img src={image} />
          <NumberWrapper>{day.format('DD')}</NumberWrapper>
          {showSth && <TodayPoint />}
        </div>
      );
    }

    return (
      <div style={{ position: 'relative' }}>
        {day.format('DD')}
        {showSth && <TodayPoint />}
      </div>
    );
  };

  const generateMonth = useMemo(() => {
    const current = langMoment(lang)(user?.streak?.from);
    const firstMonthDay =
      langMoment(lang)(user?.streak?.from).startOf('month').day() === 0
        ? 7
        : langMoment(lang)(user?.streak?.from).startOf('month').day();
    const lastMonthDay =
      langMoment(lang)(user?.streak?.from).endOf('month').day() === 0
        ? 7
        : langMoment(lang)(user?.streak?.from).endOf('month').day();

    const arr = [];
    let day = langMoment(lang)(user?.streak?.from).startOf('month');

    for (
      let i = 1;
      i <=
      getNumWeeksForMonth(
        parseInt(current.format('YYYY')),
        parseInt(current.format('MM')),
      );
      i++
    ) {
      for (let j = 1; j <= 7; j++) {
        if (i === 1) {
          if (j >= firstMonthDay) {
            arr.push(<DayElement>{getProperItem(day, j)}</DayElement>);
          } else {
            arr.push(<DayElement></DayElement>);
          }
        } else if (
          i ===
          getNumWeeksForMonth(
            parseInt(current.format('YYYY')),
            parseInt(current.format('MM')),
          )
        ) {
          if (j <= lastMonthDay) {
            arr.push(<DayElement>{getProperItem(day, j, true)}</DayElement>);
          } else {
            arr.push(<DayElement></DayElement>);
          }
        } else {
          arr.push(<DayElement>{getProperItem(day, j, true)}</DayElement>);
        }
      }
    }

    return arr;
  }, [user]);

  const getStreakTo = (streak) => {
    const from = langMoment(lang)(streak.maxStreakStartAt).format('YYYY-MM-DD');
    const to = langMoment(lang)(streak.maxStreakStartAt)
      .add(streak.maxStreak)
      .format('YYYY-MM-DD');

    if (from !== to) {
      return ` - ${langMoment(lang)(streak.maxStreakStartAt)
        .add(streak.maxStreak)
        .format('MM/YYYY')}`;
    }

    return '';
  };

  const handleOtherStreakMutation = useMutation({
    mutationFn: (payload) => loadMonthSteak(payload),
    onSuccess: ({ data }) => {
      setUser((prev) => ({
        ...prev,
        streak: {
          ...prev.streak,
          from: data.fromMonth,
          to: data.toMonth,
          monthStreaks: data.monthStreaks,
        },
      }));
    },
  });

  const handleOtherStreak = (direction) => {
    const payload = {
      from:
        direction === 'left'
          ? langMoment(lang)(user.streak.from)
              .subtract('1', 'month')
              .format('YYYY-MM-DD')
          : langMoment(lang)(user.streak.from)
              .add('1', 'month')
              .format('YYYY-MM-DD'),
      to:
        direction === 'left'
          ? langMoment(lang)(user.streak.to)
              .subtract('1', 'month')
              .format('YYYY-MM-DD')
          : langMoment(lang)(user.streak.to)
              .add('1', 'month')
              .format('YYYY-MM-DD'),
    };

    handleOtherStreakMutation.mutate(payload);
  };

  return (
    <>
      {(loadDataMutation.isLoading ||
        updateUserImageMutation.isLoading ||
        loadWordsMutation.isLoading ||
        handleOtherStreakMutation.isLoading) && <Loading />}
      {showCancelPopup && (
        <CancelPopUp
          setClose={setShowCancelPopup}
          handleSave={unsubscribeMutation}
        />
      )}
      {changeName && <ChangeName setClose={setChangeName} />}
      {changePasswordValue && (
        <ChangePasswordComponent setClose={setChangePasswordValue} />
      )}
      {profileChange && <ProfileChange setClose={setProfileChange} />}
      <Wrapper>
        <MainDataWrapper>
          <div
            style={{ position: 'relative' }}
            onClick={() => setProfileChange(true)}
          >
            <ImageWrapper>
              <img
                alt=""
                src={user?.user_profile?.base64 || editProfileImage}
                style={{ width: '100%', height: 'auto', cursor: 'pointer' }}
              />
            </ImageWrapper>
          </div>
          <UserDataWrapper>
            <UserNameWrapper>
              {user?.name} {user?.surname}{' '}
            </UserNameWrapper>
            <EmialWrapper>
              {top.accessTo}{' '}
              {langMoment(lang)(user?.order?.expired_date).format('DD.MM.YYYY')}
              <FontAwesomeIcon
                icon={faEdit}
                style={{
                  color: '#650675',
                  fontSize: '14px',
                  marginLeft: '25px',
                  cursor: 'pointer',
                }}
                onClick={() => navigate('/profile/edit')}
              />
            </EmialWrapper>
          </UserDataWrapper>
        </MainDataWrapper>
        <BackgroundGrey>
          <Card>
            <TitleSection>
              <div />
              {middle.yourMedals}
              <div />
            </TitleSection>
            <BoxWrapper>
              <Box>
                <ImageBoxWrapper>
                  <img
                    src={user?.streak?.maxStreak > 0 ? Sreak : SreakDefault}
                  />
                </ImageBoxWrapper>
                <TitleBox>
                  {user?.streak?.maxStreak}{' '}
                  {user?.streak?.maxStreak === 1
                    ? middle.oneDay
                    : middle.twoDays}
                </TitleBox>
                <SubTitleBox>{middle.streakRecord}</SubTitleBox>
              </Box>
              <Box>
                <ImageBoxWrapper>
                  <img src={GrowUp} />
                </ImageBoxWrapper>
                <TitleBox>{user?.stats?.total}</TitleBox>
                <SubTitleBox>{middle.words}</SubTitleBox>
                <div></div>
              </Box>
              <Box>
                <ImageBoxWrapper>
                  <img src={Clock} />
                </ImageBoxWrapper>
                <TitleBox>{user?.streak?.minutes} min</TitleBox>
                <SubTitleBox>{middle.learningTime}</SubTitleBox>
              </Box>
            </BoxWrapper>
          </Card>
          <BoxWrapperDesktop>
            <Card style={{ background: 'white' }}>
              <TitleSection style={{ fontSize: '16px' }}>
                {' '}
                <div />
                {middle.streak} <div />
              </TitleSection>
              {user?.maxStreak > 0 && (
                <SubtitleForStreak
                  style={{
                    fontSize: '14px',
                  }}
                >
                  {middle.yourRecord}{' '}
                  <b>
                    {user?.maxStreak}{' '}
                    {user?.maxStreak === 1 ? middle.oneDay : middle.twoDays}
                  </b>{' '}
                  {langMoment(lang)(user?.maxStreakStartAt).format('MM/YYYY')}
                  {getStreakTo(user?.streak)}
                </SubtitleForStreak>
              )}
              {user?.streak?.currentStreak > 0 && (
                <SubtitleForStreak
                  style={{
                    fontSize: '14px',
                  }}
                >
                  {middle.currentRecord}{' '}
                  <b>
                    {user?.streak?.currentStreak}{' '}
                    {user?.streak?.currentStreak === 1
                      ? middle.oneDay
                      : middle.twoDays}
                  </b>
                </SubtitleForStreak>
              )}
              <ChartWrapper>
                {showCalendar ? (
                  <>
                    <div>
                      <div
                        style={{
                          display: 'flex',
                          width: '100%',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                          padding: '0 15px',
                          boxSizing: 'border-box',
                          maxWidth: '330px',
                          margin: '0 auto',
                        }}
                      >
                        <IconWrapperArr
                          onClick={() => handleOtherStreak('left')}
                        >
                          <img src={ArrLeft} style={{ width: '100%' }} />
                        </IconWrapperArr>
                        <FromToStyle>
                          {langMoment(lang)(user?.streak?.from).format('MMMM')}
                        </FromToStyle>
                        <IconWrapperArr
                          onClick={() => handleOtherStreak('right')}
                        >
                          <img src={ArrRight} style={{ width: '100%' }} />
                        </IconWrapperArr>
                      </div>
                      <DaysWrapper>
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'center',
                          }}
                        >
                          {days.map((d) => (
                            <DayTopElement>{d}</DayTopElement>
                          ))}
                        </div>
                        <div
                          style={{
                            display: 'flex',
                            flexWrap: 'wrap',
                            justifyContent: 'center',
                          }}
                        >
                          {generateMonth}
                        </div>
                      </DaysWrapper>
                    </div>
                  </>
                ) : (
                  <>
                    {user?.streak?.streakStats?.map((ss, index, arr) => (
                      <div>
                        <div
                          style={{
                            width: '32px',
                          }}
                        >
                          <img
                            src={getStreakImage(ss, index, user?.streak)}
                            style={{
                              width: '100%',
                              height: 'auto',
                            }}
                          />
                        </div>
                        <ChartStatsSubtitle
                          style={{
                            fontWeight:
                              index === arr.length - 1 ? 'bold' : 'none',
                          }}
                        >
                          {ss.date}
                        </ChartStatsSubtitle>
                      </div>
                    ))}
                  </>
                )}
              </ChartWrapper>
              <div onClick={() => setShowCalendar((prev) => !prev)}>
                <img src={showCalendar ? ArrowUp : ArrowDown} />
              </div>
            </Card>
            <Card style={{ background: 'white' }}>
              <TitleSection>
                <div />
                {middle.words} <div />
              </TitleSection>
              <TitleSection>
                <div />
                <div>
                  {middle.wordsInWeek}{' '}
                  <b>
                    {user?.stats?.byDays.reduce(
                      (prev, next) => prev + next.amount,
                      0,
                    )}
                  </b>
                </div>
                <div />
              </TitleSection>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  width: '300px',
                  margin: '0 auto',
                }}
              >
                <IconWrapperArr onClick={() => handleOtherWords('left')}>
                  <img src={ArrLeft} style={{ width: '100%' }} />
                </IconWrapperArr>
                <FromToStyle>
                  {langMoment(lang)(user?.stats?.from).format('DD MMM')}
                  {' - '}
                  {langMoment(lang)(user?.stats?.to).format('DD MMM')}
                </FromToStyle>
                <IconWrapperArr onClick={() => handleOtherWords('right')}>
                  <img src={ArrRight} style={{ width: '100%' }} />
                </IconWrapperArr>
              </div>
              <StatsWrapperDate>
                {user?.stats?.byDays?.map((d) => (
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      gap: '5px',
                    }}
                  >
                    <div
                      style={{
                        height: '100px',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'end',
                        alignItems: 'center',
                      }}
                    >
                      <ChartStatsTitle>{d.amount}</ChartStatsTitle>
                      <Height height={d.amount / 5} />
                    </div>
                    <ChartStatsSubtitle>{d.date}</ChartStatsSubtitle>
                  </div>
                ))}
              </StatsWrapperDate>
            </Card>
          </BoxWrapperDesktop>
        </BackgroundGrey>
        <BackgroundGrey>
          <BoxWrapperDesktop>
            <Card style={{ background: 'white' }}>
              <TitleSection>
                <div style={{ width: '50px' }} />
                {bottom.yourTutor}{' '}
                <ButtonChange onClick={() => navigate('/profile/tutor')}>
                  {bottom.change}
                  <ImageWrapperMenu style={{ width: '4px', height: '8px' }}>
                    <img src={ArrowRight} />
                  </ImageWrapperMenu>
                </ButtonChange>
              </TitleSection>
              <TutorWrapper>
                <TutorImageWrapper>
                  <img src={user?.speaker?.icon?.base64} />
                </TutorImageWrapper>
                <div>
                  <TitleSpeaker style={{ textAlign: 'left' }}>
                    {user?.speaker?.name}
                  </TitleSpeaker>
                  <SubTitleSpeaker
                    style={{ textAlign: 'left', marginTop: '6px' }}
                  >
                    {user?.speaker?.description}
                  </SubTitleSpeaker>
                </div>
              </TutorWrapper>
            </Card>
            <Card style={{ background: 'white' }}>
              <Section onClick={() => navigate('/profile/learning')}>
                <SectionTittle> {bottom.learningSetting}</SectionTittle>
                <ImageWrapperMenu>
                  <img src={ArrowRight} />
                </ImageWrapperMenu>
              </Section>
              <Border />
              <Section onClick={() => navigate('/profile/interests')}>
                <SectionTittle>{bottom.hobby}</SectionTittle>
                <ImageWrapperMenu>
                  <img src={ArrowRight} />
                </ImageWrapperMenu>
              </Section>
              <Border />
              <Section onClick={() => navigate('/profile/your-plan')}>
                <SectionTittle>{bottom.yourPlan}</SectionTittle>
                <ImageWrapperMenu>
                  <img src={ArrowRight} />
                </ImageWrapperMenu>
              </Section>
              <Border />
              <Section onClick={() => setShowOpinion(true)}>
                <SectionTittle>{bottom.shareOpinion}</SectionTittle>
                <ImageWrapperMenu>
                  <img src={ArrowRight} />
                </ImageWrapperMenu>
              </Section>
              <Border />
              <Section onClick={() => navigate('/profile/help')}>
                <SectionTittle>{bottom.FAQ}</SectionTittle>
                <ImageWrapperMenu>
                  <img src={ArrowRight} />
                </ImageWrapperMenu>
              </Section>
              <Border />
              <FlexOne style={{ flex: 1 }}></FlexOne>
              <Section>
                <LogoutWrapper onClick={() => handleLogout()}>
                  <div
                    style={{
                      width: '16px',
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    <img
                      alt=""
                      src={IconLogin}
                      style={{ width: '100%', height: 'auto' }}
                    />{' '}
                  </div>
                  <Logout>{bottom.logout}</Logout>
                </LogoutWrapper>
              </Section>
            </Card>
          </BoxWrapperDesktop>
        </BackgroundGrey>
        <div style={{ marginTop: '20px' }}>
          <WrapperTerms style={{ height: '40px' }}>
            <StyledLink
              onClick={() =>
                window.open(generateLinkToProductPage(lang, common.privacyLink))
              }
            >
              {privacy}{' '}
            </StyledLink>{' '}
            {span}{' '}
            <StyledLink
              onClick={() =>
                window.open(generateLinkToProductPage(lang, common.termsLink))
              }
            >
              {rules}
            </StyledLink>
          </WrapperTerms>
        </div>
      </Wrapper>
      {showOpinion && (
        <WriteOpinion setShow={setShowOpinion} from={OPINION_OPTIONS.PROFILE} />
      )}
    </>
  );
};

export default Profile;
