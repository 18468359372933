import PopUp from "@/common/components/PopUp";
import { useLanguageData } from "@/common/hooks/useLanguageData";
import React from "react";
import styled from "styled-components";

const Title = styled.h3`
  color: #05275d;
  text-align: center;
  font-family: Work Sans;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%; /* 44.8px */

  @media (max-width: 600px) {
    font-size: 24px;
  }
`;

const Description = styled.p`
  color: #05275d;
  text-align: center;
  font-family: Work Sans;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%; /* 28px */

  @media (max-width: 600px) {
    font-size: 16px;
  }
`;

const StartButton = styled.button`
  display: inline-flex;
  padding: 17px 46px 14px 45px;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
  background: #650675;

  color: #fff;
  cursor: pointer;
  text-align: center;
  font-family: Work Sans;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.8px;
  text-transform: uppercase;

  @media (max-width: 600px) {
    font-size: 16px;
  }
`;

const Wrapper = styled.div`
  max-width: 800px;

  @media (max-width: 600px) {
    max-width: 300px;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin: 50px 0;
`;

const FotoModeWelcome = ({ setClose }) => {
  const { title, subtitle, start } = useLanguageData(
    "features.fotoMode.welcome"
  );

  const handleClose = () => {
    localStorage.setItem("foto_mode_instruction", false);
    setClose(null);
  };

  return (
    <PopUp setClose={handleClose}>
      <Wrapper>
        <Title>{title}</Title>
        <Description>{subtitle}</Description>
        <ButtonWrapper>
          <StartButton onClick={() => handleClose()}>{start}</StartButton>
        </ButtonWrapper>
      </Wrapper>
    </PopUp>
  );
};

export default FotoModeWelcome;
