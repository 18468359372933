import React from 'react';
import styled from 'styled-components';
import Logo from '../images/logo-dark.svg';
import Fire from './images/fire.svg';
import GrowUp from './images/grow-up.svg';
import { Link } from 'react-router-dom';

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  width: 100%;
  margin-top: 20px;
`;

const ImageWrapper = styled.div`
  width: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 20px;

  img {
    width: 100%;
    height: auto;
  }
`;

const CountersWrapper = styled.div`
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-right: 20px;
`;

const NumbersWrapper = styled(Link)`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  text-decoration: none;
`;

const Number = styled.div`
  color: #000;
  font-family: 'Work Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 110%; /* 15.4px */
`;

const TopBar = ({ numberOne = 0, numberTwo = 0 }) => {
  return (
    <Wrapper>
      <ImageWrapper>
        <img src={Logo} />
      </ImageWrapper>
      <CountersWrapper>
        <NumbersWrapper to={'/profile'}>
          <img src={Fire} />
          <Number>{numberOne}</Number>
        </NumbersWrapper>
        <NumbersWrapper to={'/profile'}>
          <img src={GrowUp} />
          <Number>{numberTwo}</Number>
        </NumbersWrapper>
      </CountersWrapper>
    </Wrapper>
  );
};

export default TopBar;
