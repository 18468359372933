import React from 'react';
import {
  TalkMessageWrapper,
  TranslationMessageWrapper,
} from '@/pages/landing/styles/wrappers';
import TranslationBotMessage from '../TranslationBotMessage/TranslationBotMessage';
import { ContentTranslationWrapper } from '@/pages/landing/styles/welcomeMessage.styles';

const BotMessageTalking = ({
  id,
  message,
  translationMessages,
  showMessagesTranslations,
  handleGetTranlationForBot,
}) => {
  const helper = () => showMessagesTranslations.includes(`bot-message-${id}`);

  return (
    <>
      <ContentTranslationWrapper id={`bot-message-${id}`}>
        <TalkMessageWrapper>{message}</TalkMessageWrapper>
        <TranslationBotMessage
          show={true}
          setShowTrnaslation={() =>
            handleGetTranlationForBot(`bot-message-${id}`, message)
          }
        />
      </ContentTranslationWrapper>
      {translationMessages.find((f) => f.name === `bot-message-${id}`) &&
        helper() && (
          <TranslationMessageWrapper className="fade__in__animation">
            {
              translationMessages.find((f) => f.name === `bot-message-${id}`)
                ?.data
            }
          </TranslationMessageWrapper>
        )}
    </>
  );
};

export default BotMessageTalking;
