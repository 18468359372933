import { styled } from "styled-components";

export const ContentWrapper = styled.div`
  display: flex;
  justify-content: left;
  gap: 10px;
  flex-grow: 1;
  width: 100%;

  @media (min-width: 600px) {
    gap: 20px;
  }
`;

export const MessageBox = styled.div`
  display: flex;
  gap: 10px;
  flex-direction: column;
  padding: 0 20px 0 0;
  width: 100%;

  @media (min-width: 600px) {
    gap: 18px;
    flex-grow: 1;
  }
`;

export const Logo = styled.img`
  width: 50px;
  height: 52px;

  @media (max-width: 600px) {
    width: 30px;
    height: 32px;
  }
`;

export const Height = styled.div`
  min-height: 600px;
`;

export const DemoSectionWrapper = styled.div`
  width: calc(100vw - 30px);
  height: 100%;
  padding: 5px;
  top: 200px;
  border-radius: 16px;

  @media (min-width: 600px) {
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    padding: 30px 8px 6px;
    flex-grow: 1;
    display: flex;
    gap: 20px;
    top: 0px;
  }
`;
