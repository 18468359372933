import React, { useRef, useState } from 'react';
import {
  ActionsWrapper,
  Card,
  EyeIconWrapper,
  Input,
  InputWrapper,
  InputsContainer,
  Label,
  LoginDataWrapper,
  SubmitButton,
  Title,
  Wrapper,
} from './RestartPassword.styles';
import { useNavigate, useParams } from 'react-router-dom';
import { useMutation } from 'react-query';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye } from '@fortawesome/free-solid-svg-icons';
import styled from 'styled-components';
import { updatePassoword } from '@/api/query/user';
import { getNavigationPath } from '@/common/navigation/getNavigationPath';
import { useLanguageData } from '@/common/hooks/useLanguageData';

const RestartPassword = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [labelType, setLabelType] = useState('password');
  const passwordRestartText = useLanguageData('passwordRestart');

  const secondPasswordRef = useRef();
  const passwordRef = useRef();

  const passwordRestartMutation = useMutation({
    mutationFn: (value) => updatePassoword(value),
    onSuccess: ({ data }) => {
      navigate(getNavigationPath('/login'));
    },
    onError: ({ data }) => {},
  });

  const handleChangePassowrd = (e) => {
    e && e.preventDefault();

    const payload = {};
    payload.userId = id;
    payload.password = passwordRef.current.value;
    payload.secondPassword = secondPasswordRef.current.value;

    if (payload.password != payload.secondPassword) {
      return;
    }

    passwordRestartMutation.mutate(payload);
  };

  const handleChangeLabel = () => {
    passwordRef?.current?.focus();

    if (labelType === 'password') {
      return setLabelType(() => 'text');
    }
    setLabelType(() => 'password');
  };

  return (
    <>
      <Wrapper>
        <Card onSubmit={handleChangePassowrd}>
          <LoginDataWrapper>
            <Title>{passwordRestartText.title}</Title>
            <InputsContainer>
              <InputWrapper>
                <Label>{passwordRestartText.password}</Label>
                <Input
                  ref={passwordRef}
                  id="pawssword-SynthiAi"
                  type={labelType}
                  required
                />
                <EyeIconWrapper onClick={handleChangeLabel}>
                  <FontAwesomeIcon tabIndex="none" icon={faEye} />
                </EyeIconWrapper>
              </InputWrapper>
              <InputWrapper>
                <Label>{passwordRestartText.repeatPassword}</Label>
                <Input
                  ref={secondPasswordRef}
                  id="pawssword-SynthiAi"
                  type={labelType}
                  required
                />
                <EyeIconWrapper onClick={handleChangeLabel}>
                  <FontAwesomeIcon tabIndex="none" icon={faEye} />
                </EyeIconWrapper>
              </InputWrapper>
            </InputsContainer>
            <ActionsWrapper>
              <SubmitButton type="submit">
                {passwordRestartText.restartPawssord}
              </SubmitButton>
            </ActionsWrapper>
          </LoginDataWrapper>
        </Card>
      </Wrapper>
    </>
  );
};

export default RestartPassword;
