import React, { useState } from 'react';
import { useMutation } from 'react-query';
import styled, { keyframes } from 'styled-components';

const dotFlashing = keyframes`
  0% { opacity: 1; }
  50% { opacity: 0.5; }
  100% { opacity: 1; }
`;

// Kontener dla animacji kropek
const DotLoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

// Stylowanie dla pojedynczej kropki
const Dot = styled.div`
  background-color: #730bdb;
  border-radius: 50%;
  width: 15px;
  height: 15px;
  margin: 0 5px;
  animation: ${dotFlashing} 1s infinite linear alternate;

  &:nth-child(1) {
    animation-delay: 0s;
  }
  &:nth-child(2) {
    animation-delay: 0.2s;
  }
  &:nth-child(3) {
    animation-delay: 0.4s;
  }
`;

const ThinkingAnimationsViolet = () => {
  return (
    <DotLoaderContainer>
      <Dot />
      <Dot style={{ width: '18px', height: '18px' }} />
      <Dot />
    </DotLoaderContainer>
  );
};

export default ThinkingAnimationsViolet;
