import Api from '../api';

const { axios: api } = Api;
const PREFIX = 'order';

export const createCustomerOrder = async (payload) => {
  const data = await api.post(`/${PREFIX}`, payload);

  return { data: data.data };
};

export const registerOnePageView = async (payload) => {
  const data = await api.post(`/${PREFIX}/one-page`, payload);
  return { data: data.data };
};

export const loadUserPlanData = async () => {
  const data = await api.get(`/${PREFIX}/user-plan-data`);

  return { data: data.data };
};
