import React from 'react';
import styled from 'styled-components';
import { useLanguageData } from '@/common/hooks/useLanguageData';

const ImageWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 14px;
  width: 280px;

  img {
    width: 100%;
    height: auto;
  }
`;

const Title = styled.div`
  color: #29185e;
  text-align: center;
  font-family: 'Work Sans';
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%; /* 33.6px */
  margin: 0;
  margin-top: 38px;
`;

const Subtitle = styled.div`
  color: #29185e;
  text-align: center;
  font-family: 'Work Sans';
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-top: 10px;
`;

const Box = styled.div`
  width: 300px;
  height: 112px;
  padding: 8px;
  flex-shrink: 0;
  border-radius: 8px;
  background: #ece5f2;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const BoxWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
  margin-top: 12px;
`;

const EndTitle = styled.div`
  color: #29185e;
  text-align: center;
  font-family: 'Work Sans';
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 0;
  margin-top: 22px;
`;
const Continue = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  display: inline-flex;
  padding: 12px 42px 12px 42px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-radius: 100px;
  background: linear-gradient(91deg, #4f17aa 1.48%, #ac02f1 99.24%);

  color: #fff;
  text-align: center;
  font-family: 'Work Sans';
  font-size: 13px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.64px;
  text-transform: uppercase;
  margin: 20px 0 0 0;
`;

const Top = styled.div`
  display: flex;
  gap: 5px;
  align-items: center;
`;

const Circle = styled.div`
  width: 22px;
  height: 22px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 22px;
  border: 1px solid #29185e;
`;

const Text = styled.div`
  white-space: pre-line;
  max-width: 270px;
  color: #29185e;
  font-family: 'Work Sans';
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

const Welcome = ({ setPage }) => {
  const { config, welcomeMobile, start } =
    useLanguageData('welcome.zeroScreen');

  const getBrowserText = () => {
    if (isSafari) {
      return config.safari;
    }
    return config.chrome;
  };

  return (
    <>
      <Title>{welcomeMobile}</Title>
      <Subtitle>{getBrowserText()?.title}</Subtitle>
      <BoxWrapper>
        <Box>
          <Top>
            <Circle>1</Circle>
            <Text>
              {getBrowserText()?.text1}
              <b
                style={{
                  fontWeight: '600',
                }}
              >
                {getBrowserText()?.text1bold}
              </b>
            </Text>
          </Top>
          <ImageWrapper>
            <img src={getBrowserText()?.image1} />
          </ImageWrapper>
        </Box>
        <Box>
          <Top>
            <Circle>2</Circle>
            <Text>
              {getBrowserText()?.text2}
              <b
                style={{
                  fontWeight: '600',
                }}
              >
                {getBrowserText()?.text2bold}
              </b>
            </Text>
          </Top>
          <ImageWrapper>
            <img src={getBrowserText()?.image2} />
          </ImageWrapper>
        </Box>
        <Box>
          <Top>
            <Circle>3</Circle>
            <Text> {getBrowserText()?.text3}</Text>
          </Top>
          <ImageWrapper>
            <img src={getBrowserText()?.image3} />
          </ImageWrapper>
        </Box>
      </BoxWrapper>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <Continue onClick={() => setPage(() => 1)}>{start}</Continue>
      </div>
    </>
  );
};

export default Welcome;
