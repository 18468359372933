import PopUp from '@/common/components/PopUp';
import React, { useState } from 'react';
import styled from 'styled-components';
import { useLanguageData } from '@/common/hooks/useLanguageData';

const Title = styled.div`
  color: #29185e;
  text-align: center;
  font-family: 'Work Sans';
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%; /* 28px */
  margin-top: 20px;

  @media (min-width: 600px) {
    width: 80vw;
    max-width: 800px;
  }
`;

const ImageWrapper = styled.div`
  margin: 0 auto;
  margin-top: 20px;
  width: 320px;

  img {
    width: 100%;
    height: auto;
  }
`;

const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;

  @media (min-width: 600px) {
    flex-direction: row;
    gap: 80px;
    margin-top: 40px;
  }
`;

const ButtonSkip = styled.button`
  color: var(--Text-color, #29185e);
  text-align: center;
  font-family: 'Work Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%; /* 22.4px */
  text-decoration-line: underline;

  width: 102px;
  height: 20px;
  flex-shrink: 0;

  text-transform: unset;
  background-color: white;
`;

const ButtonNext = styled.div`
  margin-top: 4px;
  display: flex;
  width: 193px;
  padding: 14px 0px 13px 0px;
  justify-content: center;
  align-items: center;

  border-radius: 100px;
  background: var(
    --Purple,
    linear-gradient(91deg, #4f17aa 1.48%, #ac02f1 99.24%)
  );

  color: #fff;
  text-align: center;
  font-family: 'Work Sans';
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.64px;
  text-transform: uppercase;
`;

const Text = styled.div`
  color: var(--Text-color, #29185e);
  font-family: 'Work Sans';
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 100%; /* 12px */
`;

const IconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 14px;
  min-height: 14px;
  max-width: 14px;
  max-height: 14px;

  margin-right: 12px;

  img {
    width: auto;
  }
`;

const TextLineFormat = styled.div`
  display: flex;
  justify-content: left;
  align-items: center;
  height: 20px;
  width: 250px;
`;

const CenterWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
  gap: 5px;
`;

const DotsWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
`;

const DailyTutorial = ({ handleClose }) => {
  const [currentPage, setCurrentPage] = useState(0);
  const { tutorial } = useLanguageData('dailyV2');

  return (
    <PopUp closeIcon={false}>
      <Title>{tutorial.title}</Title>
      <ImageWrapper>
        <img src={tutorial.screenConf[currentPage]} />
      </ImageWrapper>
      <CenterWrapper>
        {tutorial.config[currentPage].map((c) => (
          <TextLineFormat>
            <IconWrapper>{c.image && <img src={c.image} />} </IconWrapper>
            <Text>{c.title}</Text>
          </TextLineFormat>
        ))}
      </CenterWrapper>
      <DotsWrapper>
        {[0, 1, 2, 3].map((index) => (
          <div
            key={index}
            style={{
              width: currentPage === index ? '8px' : '6px',
              height: currentPage === index ? '8px' : '6px',
              borderRadius: '50%',
              backgroundColor: currentPage === index ? '#730BDB' : '#D9D9D9',
              margin: '0 5px',
              marginTop: '30px',
            }}
          />
        ))}
      </DotsWrapper>
      {currentPage === 3 ? (
        <>
          <ButtonsWrapper>
            <ButtonNext onClick={() => handleClose()}>
              {tutorial.start}
            </ButtonNext>
          </ButtonsWrapper>
        </>
      ) : (
        <ButtonsWrapper>
          <ButtonSkip onClick={() => handleClose()}>{tutorial.skip}</ButtonSkip>
          <ButtonNext onClick={() => setCurrentPage((prev) => prev + 1)}>
            {tutorial.next}
          </ButtonNext>
        </ButtonsWrapper>
      )}
    </PopUp>
  );
};

export default DailyTutorial;
