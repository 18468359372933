import React, { useContext, useEffect, useMemo, useState } from 'react';
import styled, { keyframes } from 'styled-components';
import ThinkingAnimations from './components/ThinkingAnimations';
import { useMutation } from 'react-query';
import { getCustomerAiPerson } from '@/api/query/ai-person';
import Timer from './components/Timer';
import CallEnd from './images/phone-cancel.svg';
import Record from './images/record.svg';
import Repeat from './images/repeat.svg';
import Recording from './images/speaking.svg';
import { Link, useNavigate } from 'react-router-dom';
import { createCallModeConversation } from '@/api/query/lead-history';
import {
  askChatGptCallMode,
  getTranslationCommonWorkflow,
  transcript,
} from '@/api/query/workflow';
import { Howl, Howler } from 'howler';
import MicRecorder from 'mic-recorder-to-mp3';
import ringingSound from './sound/ringing.mp3';
import callEndSound from './sound/call_end.mp3';
import TranslationImage from './images/translation.svg';
import SmallTranslationImage from './images/smaill-translation.svg';
import CloseIcon from './images/close-icon.svg';
import EndPopUp from './components/EndPopUp';
import ThinkingAnimationsViolet from './components/ThinkingAnimationsViolet';
import { MessageContext } from '@/common/contexts/message-context/MessageContext';
import { useLanguageData } from '@/common/hooks/useLanguageData';

const Mp3Recorder = new MicRecorder({ bitRate: 128 });

const TopWrapperPopup = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 16px 16px 0 16px;

  div {
    min-width: 20px;
  }
`;

const PopUp = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 350px;
  overflow-y: auto;
  overflow-x: hidden;
  background-color: white;
  z-index: 9999;
  border-radius: 8px 8px 0px 0px;
  display: flex;
  flex-direction: column;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 16px;
`;

const ButtonClose = styled.button`
  display: flex;
  width: 245px;
  padding: 13px 0px;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
  background: var(
    --Purple,
    linear-gradient(91deg, #4f17aa 1.48%, #ac02f1 99.24%)
  );

  color: #fff;
  text-align: center;
  font-family: 'Work Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.56px;
  text-transform: uppercase;
`;

const Wrapper = styled.div`
  width: 100vw;
  height: 100%;
  background: linear-gradient(220deg, #4322c6 28.95%, #48068a 74.94%);
  position: relative;

  @media (min-width: 600px) {
    width: 100%;
  }
`;

const TranslationText = styled.div`
  color: #29185e;
  font-family: 'Work Sans';
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%; /* 19.2px */

  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
`;

const Title = styled.div`
  color: #fff;
  text-align: center;
  font-family: 'Work Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%; /* 16.8px */
  min-height: 20px;
`;

const Caller = styled.div`
  color: #fff;
  text-align: center;
  font-family: 'Work Sans';
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.48px;
`;

const ImageWrapper = styled.div`
  margin: 20px 0;
  width: 200px;
  height: 200px;
  border-radius: 50%;
  position: relative;
  background: ${(prev) => (prev.voiceTalking ? 'white' : '#4f17aa')};
  z-index: 8;

  span {
    animation: ${(prev) =>
      prev.voiceTalking ? 'pulsAnimation 2s ease-in-out infinite' : ''};
    z-index: -1;
  }

  img {
    border-radius: 50%;
    z-index: 9;
    width: 100%;
    height: 100%;
  }
`;

const TopInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  padding-top: 40px;
`;

const BottomInfo = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: 20px;
  position: absolute;
  bottom: 40px;
`;

const StopTalking = styled(Link)`
  display: flex;
  width: 60px;
  height: 60px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 84px;
  background: #fc0341;
`;

const Reapeat = styled.div`
  width: 60px;
  height: 60px;
`;

const MiddleOption = styled.div`
  width: 100px;
  height: 100px;
`;

const MiddleOptionThinking = styled.div`
  width: 100px;
  height: 100px;
  background-color: white;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Translation = styled.div`
  position: absolute;
  right: 20px;
  top: 100px;
`;

const TutorText = styled.div`
  margin: 0 16px;
  color: var(--Text-color, #29185e);
  font-family: 'Work Sans';
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%; /* 14.4px */

  white-space: pre-line;
`;

const TranslatedText = styled.div`
  margin: 0 16px;
  color: var(--Text-color, #29185e);
  font-family: 'Work Sans';
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%; /* 14.4px */
  white-space: pre-line;
`;

const STATUSES = {
  NONE: 'none',
  WAITING: 'waiting',
  RECORDING: 'recording',
};

const CallMode = () => {
  const [tutor, setTutor] = useState({});
  const [info, setInfo] = useState();
  const [conversation, setConversation] = useState();
  const [audioInstance, setAudioInstance] = useState(null);
  const [voiceTalking, setVoiceTalking] = useState(false);
  const [status, setStatus] = useState(STATUSES.NONE);
  const [showTranslation, setShowTranslation] = useState(false);
  const [ringingInstance, setRingingInstance] = useState(null);
  const navigate = useNavigate();
  const [endPopUp, setEndPopUp] = useState();

  const { transcriptionError } = useLanguageData('learn.callMode');
  const { addMessage } = useContext(MessageContext);

  const speak = async (data) => {
    const blob = new Blob([new Uint8Array(data.speaking.data)], {
      type: 'audio/mp3',
    });
    const url = await (function blobToBase64(blob) {
      return new Promise((resolve, _) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result);
        reader.readAsDataURL(blob);
      });
    })(blob);

    const audio = new Howl({
      src: [url],
      onplay: () => {
        setVoiceTalking(true);
      },
      onpause: () => {
        setVoiceTalking(false);
      },
      onstop: () => {
        setVoiceTalking(false);
      },
      onmute: () => {
        setVoiceTalking(false);
      },
      onfade: () => {
        setVoiceTalking(false);
      },
      onend: () => {
        setVoiceTalking(false);
      },
      onunload: () => {
        setVoiceTalking(false);
      },
    });

    setAudioInstance(audio);
    await audio.play();
  };

  const startConversationMutation = useMutation({
    mutationFn: () => createCallModeConversation(),
    onSuccess: (data) => {
      setConversation(data);
      const payload = {};
      payload._id = data._id;
      askChatGptMutation.mutate(payload);
    },
  });

  const getTranscriptionMutation = useMutation({
    mutationFn: (payload) => transcript(payload),
    onSuccess: ({ data }) => {
      const payload = {};
      payload._id = conversation._id;
      payload.message = data;
      askChatGptMutation.mutate(payload);
    },
    onError: () => {
      Howler?.unload();
      setRingingInstance(null);
      setStatus(() => STATUSES.NONE);
      addMessage(transcriptionError, 'error');
    },
  });

  const askChatGptMutation = useMutation({
    mutationFn: (payload) => askChatGptCallMode(payload),
    onSuccess: async ({ data }) => {
      const path = window.location.pathname;

      if (!path.includes('call-mode')) {
        return;
      }

      setInfo(data);
      Howler?.unload();
      setRingingInstance(null);
      await speak(data);
      setStatus(() => STATUSES.NONE);
    },
  });

  const loadTutorMutation = useMutation({
    mutationFn: () => getCustomerAiPerson(),
    onSuccess: ({ data }) => {
      setTutor(() => data);
      startConversationMutation.mutate();
    },
    onError: () => {},
  });

  const handleCallModeWorkflow = () => {
    if (voiceTalking) {
      return;
    }

    if (status === STATUSES.NONE) {
      startRecording();
    } else if (status === STATUSES.RECORDING) {
      stopRecording();
    }
    return;
  };

  const renderElement = useMemo(() => {
    if (status === STATUSES.RECORDING) {
      return (
        <MiddleOption onClick={handleCallModeWorkflow}>
          <img src={Recording} alt="Record/Stop" />
        </MiddleOption>
      );
    }
    if (status === STATUSES.WAITING) {
      return (
        <MiddleOptionThinking onClick={handleCallModeWorkflow}>
          <ThinkingAnimationsViolet />
        </MiddleOptionThinking>
      );
    }

    return (
      <MiddleOption onClick={handleCallModeWorkflow}>
        <img src={Record} alt="Record/Stop" />
      </MiddleOption>
    );
  }, [status, voiceTalking]);

  const reapeatMutation = async () => {
    if (audioInstance) {
      audioInstance.stop();
    }
    await speak(info);
  };

  const startRecording = async () => {
    Mp3Recorder.start()
      .then(() => {
        setStatus(STATUSES.RECORDING);
      })
      .catch((e) => alert('PLEASE ALLOW MICROFON'));
  };

  const stopRecording = () => {
    Mp3Recorder.stop()
      .getMp3()
      .then(([buffer, blob]) => {
        const file = new File([blob], 'to_translate.mp3', {
          type: 'audio/mpeg',
          lastModified: new Date().getTime(),
        });
        const formData = new FormData();
        formData.append('file', file);
        getTranscriptionMutation.mutate(formData);
      })
      .catch((e) => console.log(e));

    setStatus(STATUSES.WAITING);
  };

  const translationMutation = useMutation({
    mutationFn: (value) => getTranslationCommonWorkflow(value),
    onSuccess: ({ data }) => {
      setShowTranslation(data.translation);
    },
  });

  const handleTranslation = () => {
    const payload = {
      message: info.text,
      type: 'CALL_MODE',
    };

    translationMutation.mutate(payload);
  };

  useEffect(() => {
    loadTutorMutation.mutate();
    navigator.mediaDevices
      .getUserMedia({ audio: true })
      .then((stream) => {
        const ringing = new Howl({
          src: [ringingSound],
          loop: true,
        });
        ringing.play();
        setRingingInstance(() => ringing);
        console.log('Permission Granted');
      })
      .catch((err) => {
        console.log('Permission Denied');
        console.log(err);
      });
  }, []);

  const playEnd = () => {
    const callEnd = new Howl({
      src: [callEndSound],
      onend: () => {
        navigate('/learn');
      },
    });

    if (audioInstance) {
      audioInstance.stop();
    }

    callEnd.play();
  };

  return (
    <>
      <Wrapper>
        <TopInfoWrapper>
          {!info ? <Title>Dzwonię do...</Title> : <Title> </Title>}
          <Caller>{tutor.name}</Caller>
          <ImageWrapper className="puls" voiceTalking={voiceTalking}>
            <span style={{ '--i': '0' }}></span>
            <span style={{ '--i': '1' }}></span>
            <span style={{ '--i': '2' }}></span>
            <span style={{ '--i': '3' }}></span>
            <img src={tutor.image} />
          </ImageWrapper>
          {info ? <Timer /> : <ThinkingAnimations />}
        </TopInfoWrapper>
        <BottomInfo>
          <Reapeat onClick={reapeatMutation}>
            <img src={Repeat} alt="Repeat" />
          </Reapeat>

          {renderElement}

          <StopTalking onClick={() => setEndPopUp(() => true)}>
            <img src={CallEnd} alt="Stop Talking" />
          </StopTalking>
        </BottomInfo>
        <Translation
          style={{ position: 'absolute', cursor: 'pointer' }}
          onClick={() => handleTranslation()}
        >
          <img src={TranslationImage} />
        </Translation>
        {showTranslation && (
          <PopUp onClick={() => setShowTranslation(false)}>
            <TopWrapperPopup>
              <div></div>
              <TranslationText>
                Tłumaczenie <img src={SmallTranslationImage} />
              </TranslationText>
              <div>
                <img src={CloseIcon} />
              </div>
            </TopWrapperPopup>
            <div>
              <TutorText>{tutor.name}:</TutorText>
              <TutorText>{info.text}</TutorText>
            </div>
            <div>
              <TranslatedText style={{ marginTop: '12px' }}>
                Tłumaczenie
              </TranslatedText>
              <TranslatedText>{showTranslation}</TranslatedText>
            </div>
            <ButtonWrapper>
              <ButtonClose onClick={() => setShowTranslation(false)}>
                Zamknij
              </ButtonClose>
            </ButtonWrapper>
          </PopUp>
        )}
        {endPopUp && <EndPopUp setClose={setEndPopUp} playEnd={playEnd} />}
      </Wrapper>
    </>
  );
};

export default CallMode;
