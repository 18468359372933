import React from 'react';
import TranslationIcon from '@/common/images/icon-translate.svg';
import { CustomImage } from './translationBotMessage.styles';
import styled from 'styled-components';

const Button = styled.button`
  width: fit-content;
  display: inline-flex;
  padding: 0;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
  background: transparent;
  text-align: center;
  font-family: Work Sans;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.8px;
  text-transform: uppercase;
  border: none;
`;

const TranslationBotMessage = ({ show, setShowTrnaslation }) => {
  return (
    show && (
      <Button onClick={setShowTrnaslation}>
        <CustomImage
          className="fade__in__animation"
          src={TranslationIcon}
          alt="translation-icon"
        />
      </Button>
    )
  );
};

export default TranslationBotMessage;
