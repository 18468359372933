import styled from 'styled-components';

export const IconWrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  align-self: center;
  position: absolute;
  cursor: pointer;
  width: 100%;

  bottom: 60px;
  padding-bottom: 10px;
  left: 0;
  display: flex;
  gap: 5px;
  flex-direction: column;

  @media (min-width: 600px) {
    margin: 0;
    bottom: 0;
    left: 0;
    padding: 12px 18px 12px 8px;
  }
`;

export const HintsWrapper = styled.div`
  margin-left: 10px;
  display: flex;
  gap: 5px;
  flex-direction: column;

  @media (min-width: 600px) {
    margin-right: 8px;
  }
`;

export const WrapperHint = styled.div`
  font-size: 12px;
  text-align: left;
  width: 75vw;
  height: auto;
  background-color: white;
  padding: 5px 10px;
  border: 1px solid #f8ba65;
  border-radius: 16px;
  line-height: 120%;
  &:hover {
    cursor: pointer;
  }

  @media (min-width: 600px) {
    width: 80%;
  }
`;

export const HintIconWrapper = styled.div`
  position: absolute;
  align-self: flex-end;
  left: 89%;
  transform: ${(props) => (props.isRotated ? 'rotate(180deg)' : '')};
  transition: transform 0.3s;

  @media (min-width: 600px) {
    left: 85%;
  }
`;

export const HintIcon = styled.img`
  width: 36px;

  @media (min-width: 600px) {
    width: 48px;
  }
`;

export const FlexWrapper = styled.div`
  display: flex;
`;

export const Input = styled.input`
  box-sizing: border-box;
  z-index: 3;
  margin-top: 15px;
  margin-left: 10px;
  display: flex;
  width: 74%;
  height: 35px;
  padding: 5px;
  padding-right: 25px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border: none;
  border-radius: 8px;
  background: #eef2f5;

  &:focus {
    outline: none;
  }

  @media (min-width: 600px) {
    width: 88%;
    padding: 24px 5% 24px 12px;
  }
`;

export const MicrophoneIconWrapper = styled.div`
  position: absolute;
  bottom: 15px;
  left: 80%;
  width: 15px;
  height: 15px;
  z-index: 30;
  color: white;
  padding-bottom: 10px;
  cursor: pointer;

  @media (min-width: 600px) {
    bottom: 33px;
    left: 88%;
    padding-bottom: 0px;
  }
`;

export const SendIconWrapper = styled.div`
  width: 35px;
  height: 35px;
  bottom: 2px;
  left: 86%;
  position: absolute;
  z-index: 30;
  padding-bottom: 10px;

  @media (min-width: 600px) {
    bottom: 18px;
    left: 93%;
    padding-bottom: 3px;
  }
`;
