import Api from '../api';
const { axios: api } = Api;
const PREFIX = 'ip-stats';

export const addIpStats = async (values) => {
  const data = await api.post(`/${PREFIX}`, values);
  return { data: data.data };
};

export const addFBStats = async (values) => {
  const data = await api.post(`/${PREFIX}/fb`, values);
  return { data: data.data };
};
