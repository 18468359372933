import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import Megan from './images/icons/Megan.png';
import { useMutation } from 'react-query';
import { getWordLessonTopType } from '@/api/query/word-lesson-top-type';
import Loading from '@/common/components/Loading';
import { useLanguageData } from '@/common/hooks/useLanguageData';
import { LanguageContext } from '@/common/contexts/LanguageContext';

const TopWrapper = styled.div`
  display: flex;
  justify-content: left;
  align-items: center;
  gap: 28px;
`;

const Text = styled.div`
  color: #29185e;
  font-family: 'Work Sans';
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 123.5%; /* 19.76px */
  letter-spacing: 0.32px;
  max-width: 180px;
`;

const Left = styled.div`
  width: 80px;

  img {
    width: 100%;
    height: auto;
  }
`;

const Subtitle = styled.div`
  color: #29185e;
  text-align: center;
  font-family: 'Work Sans';
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%; /* 16.8px */
  margin-top: 8px;
`;

const HobbyWrapper = styled.div`
  display: flex;
  margin-top: 14px;
  justify-content: center;
  gap: 10px;
  flex-wrap: wrap;
  max-width: 800px;
`;

const HobbyElement = styled.div`
  cursor: pointer;
  display: flex;
  padding: 8px;
  justify-content: left;
  align-items: center;
  gap: 10px;
  width: 140px;
  border-radius: 10px;
  background: ${(props) =>
    props?.isActive ? '#730BDB' : '#ECE5F2'} !important;
  color: ${(props) => (props?.isActive ? 'white' : '#29185e')} !important;
  font-family: Lato;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 123.5%;
  letter-spacing: 0.28px;
`;

const SubmitButton = styled.button`
  cursor: pointer;
  display: inline-flex;
  width: 192px;
  padding: 14px 0px 14px 0px;
  justify-content: center;
  align-items: center;
  color: white;

  color: #fff;
  text-align: center;
  font-family: 'Work Sans';
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.64px;
  text-transform: uppercase;

  background: linear-gradient(91deg, #4f17aa 1.48%, #ac02f1 99.24%);
  margin-top: 24px;

  &:disabled {
    background: #d9d9d9 !important;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 130px;
`;

const Hobby = ({ data, setPage, setData }) => {
  const { title, subtitle, next } = useLanguageData('welcome.thirdScreen');
  const { lang } = useContext(LanguageContext);

  const [hobbies, setHobbies] = useState([]);
  const [selectedHobby, setSelectedHobby] = useState([]);

  const loadHobbyMutation = useMutation({
    mutationFn: () => getWordLessonTopType(),
    onSuccess: ({ data }) => {
      setHobbies(data);
    },
  });

  const addToSelect = (elemet) => {
    if (selectedHobby.includes(elemet)) {
      setSelectedHobby((prev) => prev.filter((p) => p != elemet));
      return;
    }

    setSelectedHobby((prev) => [...prev, elemet]);
  };

  useEffect(() => {
    loadHobbyMutation.mutate();
  }, []);

  return (
    <>
      {loadHobbyMutation.isLoading && <Loading />}
      <TopWrapper>
        <Left>
          <img src={data.tutor?.tutorial?.base64 || Megan} />
        </Left>
        <Text>{title}</Text>
      </TopWrapper>
      <Subtitle>{subtitle}</Subtitle>
      <HobbyWrapper>
        {hobbies
          .sort((a, b) => a.number - b.number)
          .map((h) => (
            <HobbyElement
              isActive={selectedHobby.includes(h._id)}
              onClick={() => addToSelect(h._id)}
            >
              <img src={h.file?.base64} width={20} />
              {
                h?.translations?.find((t) => t.languageShort === lang)
                  ?.translation
              }
            </HobbyElement>
          ))}
      </HobbyWrapper>
      <ButtonWrapper>
        <SubmitButton
          onClick={() => {
            setData((prev) => ({
              ...prev,
              hobbies: selectedHobby,
            }));
            setPage(() => 5);
          }}
          disabled={selectedHobby.length < 3}
        >
          {next}
        </SubmitButton>
      </ButtonWrapper>
    </>
  );
};

export default Hobby;
