import Api from '../api';
const { axios: api } = Api;
const PREFIX = 'customer';

export const checkActive = async (id) => {
  const data = await api.get(`/${PREFIX}/check-active/${id}`);
  return { data: data.data };
};

export const findCustomerWithStats = async (payload) => {
  const data = await api.get(`/${PREFIX}/customer/stats/${payload._id}`);
  return { data: data.data };
};

export const changeUserDailyMinutes = async (payload) => {
  const data = await api.patch(`/${PREFIX}/change-user-daily-minutes`, payload);
  return { data: data.data };
};

export const loadMonthSteak = async (payload) => {
  const data = await api.get(
    `/${PREFIX}/load-streak/${payload.from}/${payload.to}`,
    payload,
  );
  return { data: data.data };
};

export const loadWords = async (payload) => {
  const data = await api.get(
    `/${PREFIX}/load-words/${payload.from}/${payload.to}`,
    payload,
  );
  return { data: data.data };
};

export const markeLanguageAsLearningLang = async (payload) => {
  const data = await api.patch(
    `/${PREFIX}/mark-language-as-learning-lang`,
    payload,
  );
  return { data: data.data };
};

export const updateUserFirstView = async (payload) => {
  const data = await api.post(`/${PREFIX}/update-user-first-view`, payload);
  return { data: data.data };
};

export const updateUserCallModePopup = async () => {
  const data = await api.post(`/${PREFIX}/update-user-call-mode-pop-up`);
  return { data: data.data };
};

export const unsubscribe = async () => {
  const data = await api.patch(`/${PREFIX}/unsubscribe`);
  return { data: data.data };
};

export const subscribe = async () => {
  const data = await api.patch(`/${PREFIX}/subscribe`);
  return { data: data.data };
};

export const updateCustomerLevel = async (payload) => {
  const data = await api.patch(`/${PREFIX}/level/`, payload);
  return { data: data.data };
};

export const updateCustomerImage = async (payload) => {
  const data = await api.patch(`/${PREFIX}/image`, payload);
  return { data: data.data };
};

export const markShowDailyPopupConversatoin = async () => {
  const data = await api.patch(`/${PREFIX}/mark-daily-tutorial-popup`);
  return { data: data.data };
};

export const findCustomer = async () => {
  const data = await api.get(`/${PREFIX}/one`);
  return { data: data.data };
};

export const updateUserFlashcardSettings = async (payload) => {
  const data = await api.patch(
    `/${PREFIX}/cutomer-flashcard-settings`,
    payload,
  );
  return { data: data.data };
};

export const findUserInfoForDashboard = async () => {
  const data = await api.get(`/${PREFIX}/dashboard`);
  return { data: data.data };
};

export const findUserInfoForLearn = async () => {
  const data = await api.get(`/${PREFIX}/learn`);
  return { data: data.data };
};

export const findFavoriteByType = async (type) => {
  const data = await api.get(`/${PREFIX}/favorite/${type}`);

  return { data: data.data };
};

export const AddToFavorite = async (payload) => {
  const data = await api.post(`/${PREFIX}/favorite`, payload);
  return { data: data.data, shouldAdd: payload?.shouldAdd };
};

export const AddToFavoriteWithFullResponse = async (payload) => {
  const data = await api.post(`/${PREFIX}/favorite-full-response`, payload);
  return { data: data.data, shouldAdd: payload?.shouldAdd };
};
