import React, { useEffect, useState } from "react";
import { useMutation } from "react-query";
import { Link, useNavigate } from "react-router-dom";
import styled from "styled-components";
import Loading from "@/common/components/Loading";
import {
  getWordLessonCustomerTopType,
  getWordLessonTopType,
} from "@/api/query/word-lesson-top-type";
import ArrLeft from "@/common/images/arr-left.svg";
import { useLanguageData } from "@/common/hooks/useLanguageData";

export const Wrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100vw;
  height: calc(var(--vh, 1vh) * 100 - 60px);
  overflow-y: auto;
  text-align: center;
  padding: 0px 10px 0 10px;
  gap: 10px;

  @media (min-width: 600px) {
    flex-grow: 1;
    height: calc(var(--vh, 1vh) * 100 - 160px);
    width: 100%;
    padding: 30px 30px 10px;
    margin: 0;
  }
`;

export const Title = styled.h2`
  color: #05275d;
  text-align: center;
  font-family: Work Sans;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.56px;
  margin: 0px;
  text-align: center;
  margin: 10px 0;
`;

const TopTitle = styled.div`
  color: #fff;
  text-align: center;
  font-family: "Work Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 123.5%; /* 24.7px */
  letter-spacing: 0.4px;
  position: absolute;
  color: white;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;

  div {
    max-width: 130px;
  }
`;

const SubTitle = styled.div`
  color: #29185e;
  text-align: center;
  font-family: "Work Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 110%; /* 17.6px */
  max-width: 400px;
`;

const BoxWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  overflow: auto;
  gap: 16px;
`;

const ImageOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(41, 24, 94, 0.5); /* Kolor nakładki */
  border-radius: 8px; /* Zaokrąglenie krawędzi */
`;

const Heart = styled.div`
  position: absolute;
  right: 5px;
  bottom: 0px;
  width: 25px;
  z-index: 2;

  img {
    width: 100%;
    height: auto;
  }
`;

const Box = styled(Link)`
  position: relative; /* Ustawiamy pozycję na relative */
  width: 160px;
  height: 160px;
  border-radius: 8px; /* Zaokrąglenie krawędzi */
  background-color: white !important;
  box-shadow: 0px 0px 11px 0px rgba(79, 167, 180, 0.2);
`;

const ImageWrapper = styled.div`
  width: 160px;
  height: 160px;
  position: relative; /* Ustawiamy pozycję na relative */
  overflow: hidden;

  img {
    border-radius: 8px;
    width: 100%;
    height: 100%;
  }
`;

const NewFlashcardList = () => {
  const [wordsTypes, setWordsTypes] = useState([]);
  const { title, subtitleTop } = useLanguageData("features.flashcards");

  const handleGetHobbyMutation = useMutation({
    mutationFn: () => getWordLessonCustomerTopType(),
    onSuccess: ({ data }) => {
      setWordsTypes(data);
    },
  });

  useEffect(() => {
    handleGetHobbyMutation.mutate();
  }, []);

  useEffect(() => {
    window.document.title = "Synthi Ai - flashcards";
  }, []);

  return (
    <>
      {handleGetHobbyMutation.isLoading && <Loading />}
      <Wrapper height={window.innerHeight}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
          }}
        >
          <Link to={"/learn"}>
            <img
              src={ArrLeft}
              style={{
                width: "12px",
              }}
            />
          </Link>
          <Title>{title}</Title>
          <div></div>
        </div>
        <SubTitle>{subtitleTop}</SubTitle>
        <BoxWrapper>
          {wordsTypes.map((d) => (
            <Box to={`/learn/flashcards/${d._id}`}>
              <ImageWrapper>
                <TopTitle>
                  <div>{d.name}</div>
                </TopTitle>
                <img src={d.bigFile.base64} alt={d.name} />
                <ImageOverlay />
              </ImageWrapper>
            </Box>
          ))}
        </BoxWrapper>
      </Wrapper>
    </>
  );
};

export default NewFlashcardList;
