import React, { useEffect, useState } from 'react';
import RobotTalkIcon from '@/common/images/robot-talk.svg';
import { DemoConversationContext } from '@/common/contexts/DemoConversation';
import {
  ContentWrapper,
  DemoSectionWrapper,
  Height,
  Logo,
  MessageBox,
} from './talking.styles';

import { TalkiMessage } from './components';
import { useMutation } from 'react-query';
import { Wrapper } from '../home/Home.styles';
import FlashcardView from './components/flashcardView/FlashcardView';
import Loading from '@/common/components/Loading';
import FotoModeWelcome from './components/FotoModeWelcome';
import { getUserImageMode } from '@/api/query/image-mode';
import ImageModeView from './components/flashcardView/ImageModeView';
import styled from 'styled-components';

const ScrolleWrapper = styled.div`
  height: calc(var(--vh, 1vh) * 100 - 290px);
  overflow-y: auto;
  width: 100%;

  @media (max-width: 600px) {
    height: calc(var(--vh, 1vh) * 100 - 120px);
    background-color: white;
  }
`;

const FotoMode = ({ setPageNumber }) => {
  const [flascardData, setFlascardData] = useState();
  const [ask, setAsk] = useState();
  const [dailyConversation, setDailyConversation] = useState();
  const [data, setData] = useState({});
  const [showButtoReccord, setShowButtoReccord] = useState(false);
  const [showLoading, setShowLoading] = useState();
  const [showText2, setShowText2] = useState(true);
  const [firstTime, setFirstTime] = useState(
    localStorage.getItem('foto_mode_instruction') === 'false' ? false : true,
  );

  const handleDailyConversationMutation = useMutation({
    mutationFn: () => getUserImageMode(),
    onSuccess: ({ data }) => {
      setFlascardData(data.imageModeResponse);
      if (data.converstaion) {
        setDailyConversation(() => data.converstaion);
      }

      if (data.imageModeResponse.answer && data.converstaion.length < 3) {
        setAsk(true);
      }
    },
  });

  useEffect(() => {
    handleDailyConversationMutation.mutate();
  }, []);

  useEffect(() => {
    window.document.title = 'Synthi Ai - foto mode';
  }, []);

  return (
    <>
      {firstTime && <FotoModeWelcome setClose={setFirstTime} />}
      <Wrapper>
        {handleDailyConversationMutation.isLoading && <Loading />}
        {flascardData && (
          <FlashcardView
            data={flascardData}
            background={'white'}
            newImage={true}
          />
        )}
        <ScrolleWrapper>
          {flascardData && (
            <ImageModeView
              data={flascardData}
              setData={setFlascardData}
              setConversation={setDailyConversation}
              setAsk={setAsk}
            />
          )}
          {flascardData?.answer && (
            <DemoSectionWrapper>
              <ContentWrapper>
                <Logo src={RobotTalkIcon} />
                <DemoConversationContext.Provider
                  value={{
                    data,
                    setData,
                    showButtoReccord,
                    setShowButtoReccord,
                    showLoading,
                    setShowLoading,
                    setPageNumber,
                    showText2,
                    setShowText2,
                    flascardData,
                    dailyConversation,
                    ask,
                    setAsk,
                  }}
                >
                  <MessageBox>
                    <TalkiMessage />
                    <Height />
                  </MessageBox>
                </DemoConversationContext.Provider>
              </ContentWrapper>
            </DemoSectionWrapper>
          )}
        </ScrolleWrapper>
      </Wrapper>
    </>
  );
};

export default FotoMode;
