import { styled } from 'styled-components';

export const SectionWrapper = styled.div`
  max-width: 950px;
  margin: -20px 0 10px 0;
  width: 100%;
  border-radius: 16px;
  background: #fff;
  box-shadow: 0px 0px 60px 0px rgba(79, 167, 180, 0.2);
  padding: 50px;
  height: 470px;

  @media (max-width: 600px) {
    height: 380px;
    min-width: 300px;
    max-width: 300px;
  }
`;

export const MessageWrapper = styled.div`
  max-width: 650px;
  width: fit-content;
  border-radius: 8px 8px 8px 0px;
  background: #edeffe;
  padding: 20px;
  color: #29185e;
  font-family: Lato;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 123.5%; /* 14.82px */
  letter-spacing: 0.24px;
`;

export const TranslationMessageWrapper = styled.div`
  text-align: left;
  max-width: 550px;
  width: fit-content;
  border-radius: 0px 0px 16px 0px;
  background: #edeffe;
  padding: 14px;
  margin-top: -0px;
  white-space: pre-wrap;
  color: #29185e;
  font-family: Lato;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 123.5%; /* 14.82px */
  letter-spacing: 0.24px;

  @media (max-width: 600px) {
    padding: 12px;
    max-width: 78vw;
    margin-top: -5px;
  }
`;

export const TalkMessageWrapper = styled(MessageWrapper)`
  padding: 14px;
  white-space: pre-wrap;

  @media (max-width: 600px) {
    padding: 12px;
    max-width: 78vw;
  }
`;

export const UserMessageWrapper = styled(MessageWrapper)`
  background: #ece5f2;
  border-radius: 16px;
  max-width: fit-content;
  align-self: flex-end;
  display: flex;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: right;
  align-items: center;
  gap: 10px;
  margin: 20px 0;

  @media (max-width: 600px) {
    margin: 10px 0;
  }
`;

export const TextInfomation = styled.div`
  display: inline-flex;
  padding: 12px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 8px 8px 0px 8px;
  background: #eef2f5;
  display: inline-flex;
  padding: 14px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  background: #ece5f2;
  font-family: Lato;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 123.5%; /* 14.82px */
  letter-spacing: 0.24px;
  text-align: left;
  max-width: 600px;
  color: #29185e;
  font-family: Lato;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 123.5%; /* 14.82px */
  letter-spacing: 0.24px;

  @media (max-width: 600px) {
    max-width: 300px;
  }
`;

export const CustomButton = styled.button`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  border-radius: 16px;
  border: 1px solid #8befd1;
  background: #e2fbf4;
  font-family: Lato;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 123.5%; /* 14.82px */
  letter-spacing: 0.24px;
  padding: 10px 20px;
  cursor: pointer;

  &:hover {
    transform: scale(1.05);
  }
  &:focus {
    outline: none;
  }

  @media (max-width: 600px) {
    padding: 8px;
  }
`;
