import { askChatGptProductSpecial, transcript } from "@/api/query/workflow";
import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { useMutation } from "react-query";
import { DemoConversationContext } from "@/common/contexts/DemoConversation";
import { TALKING_ROLES } from "./TalkiMessage";
import Animation1 from "@/common/images/animation-1.gif";
import { typeWriter } from "@/common/functions/typing";
import { Howl } from "howler";
import AnimationRecording from "./AnimationRecording";
import IconCloseHints from "@/common/icons/icon-circle-chevron-down.svg";
import {
  HintsWrapper,
  IconWrapper,
  FlexWrapper,
  WrapperHint,
  HintIcon,
  HintIconWrapper,
} from "./TalkingMicrofon.styles";
import { FROM_SOURCE_TYPES } from "@/common/constants/types";
import MessageBar from "@/common/components/chat/MessageBar";

const TalkingMicrofon = ({
  setCurrent,
  setConversationWithChatGPT,
  conversationWithChatGPT,
}) => {
  const [showHints, setShowHints] = useState(false);
  const [hints, setHints] = useState([]);
  const [speechLasts, setSpeechLasts] = useState();

  const { showLoading, dailyConversation } = useContext(
    DemoConversationContext
  );

  const presenceChecker = useRef();

  const [isRecording, setIsRecording] = useState(false);

  const transcriptMutation = useMutation({
    mutationFn: (file) => transcript(file),
    onSuccess: ({ data }) => {
      if (!data && data?.length === 0) return;

      setConversationWithChatGPT((prev) => [
        ...prev,
        { role: TALKING_ROLES.USER, message: data },
      ]);

      const payload = {};
      payload.message = data;
      payload._id = dailyConversation._id;
      payload.source = FROM_SOURCE_TYPES.SPEAK;

      getResponseForChatGptMutation.mutate(payload);
    },
  });

  const getResponseForChatGptMutation = useMutation({
    mutationFn: (value) => askChatGptProductSpecial(value),
    onSuccess: async ({ data }) => {
      setHints(() => data.hints);

      const blob = new Blob([new Uint8Array(data.speaking.data)], {
        type: "audio/mp3",
      });
      const url = await (function blobToBase64(blob) {
        return new Promise((resolve, _) => {
          const reader = new FileReader();
          reader.onloadend = () => resolve(reader.result);
          reader.readAsDataURL(blob);
        });
      })(blob);

      const numberOfUserQuestion =
        (conversationWithChatGPT.filter((c) => c.role === TALKING_ROLES.BOT)
          ?.length || 0) + 1;

      window.gtag("event", `speak_question_${numberOfUserQuestion}`);

      const audio = new Howl({
        src: [url],
        onend: () => {
          setSpeechLasts(() => false);
        },
        onunload: () => {
          setSpeechLasts(() => false);
        },
      });

      setSpeechLasts(() => true);

      if (presenceChecker?.current && !document.hidden) {
        audio.play();
      }

      typeWriter({
        text: data.text,
        setText: setCurrent,
        typing: 45,
        onEnd: () => {
          setCurrent(null);
          setSpeechLasts(() => false);
          setConversationWithChatGPT((prev, i) => [
            ...prev,
            { index: i, role: TALKING_ROLES.BOT, message: data.text },
          ]);
        },
      });
    },
  });

  const handleSendMessage = (messageInput, hint = false) => {
    if (!messageInput) return;

    setHints([]);

    const message = messageInput;

    setConversationWithChatGPT((prev) => [
      ...prev,
      { role: TALKING_ROLES.USER, message: message },
    ]);

    const payload = {};
    payload.message = message;
    payload._id = dailyConversation._id;
    payload.source = hint ? FROM_SOURCE_TYPES.HINT : FROM_SOURCE_TYPES.WRTIE;

    getResponseForChatGptMutation.mutate(payload);
  };

  const isResponding = useMemo(
    () =>
      speechLasts ||
      getResponseForChatGptMutation.isLoading ||
      transcriptMutation.isLoading,
    [
      getResponseForChatGptMutation.isLoading,
      speechLasts,
      transcriptMutation.isLoading,
    ]
  );

  const handleHints = () => {
    if (showHints) {
      return setShowHints(false);
    }

    setShowHints(true);
  };

  useEffect(() => {
    if (dailyConversation) {
      const payload = {};
      payload._id = dailyConversation._id;

      getResponseForChatGptMutation.mutate(payload);
    }
  }, [dailyConversation]);

  useEffect(() => {
    const element = document.getElementById(`bottom-info`);
    element?.scrollIntoView({ behavior: "smooth" });
  }, [transcriptMutation?.isLoading, getResponseForChatGptMutation?.isLoading]);

  return (
    <>
      {transcriptMutation.isLoading && (
        <div style={{ textAlign: "right", minHeight: "37px" }}>
          <img
            alt=""
            src={Animation1}
            style={{ width: "37px", height: "37px" }}
          />
        </div>
      )}
      {getResponseForChatGptMutation.isLoading && (
        <div style={{ textAlign: "left", minHeight: "37px" }}>
          <img
            alt=""
            src={Animation1}
            style={{ width: "37px", height: "37px" }}
          />
        </div>
      )}
      {isRecording && <AnimationRecording />}
      {showLoading && (
        <div
          style={{
            textAlign: "center",
            marginTop: "-40px",
            marginLeft: "-80px",
          }}
        >
          <img alt="" src={Animation1} style={{ width: "120px" }} />
        </div>
      )}
      <div ref={presenceChecker} />
      <IconWrapper>
        <FlexWrapper>
          {showHints && !isResponding && (
            <HintsWrapper>
              {hints?.map((h) => (
                <WrapperHint onClick={() => handleSendMessage(h, true)}>
                  {h}
                </WrapperHint>
              ))}
            </HintsWrapper>
          )}
          {!!hints?.length && !isResponding && (
            <HintIconWrapper isRotated={showHints}>
              <HintIcon
                alt=""
                src={IconCloseHints}
                onClick={() => handleHints()}
              />
            </HintIconWrapper>
          )}
        </FlexWrapper>
        <MessageBar
          isBlock={
            getResponseForChatGptMutation.isLoading ||
            transcriptMutation.isLoading
          }
          sendText={handleSendMessage}
          sendVoice={(data) => transcriptMutation.mutate(data)}
        />
      </IconWrapper>
    </>
  );
};

export default TalkingMicrofon;
