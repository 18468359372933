import React, { useContext } from 'react';
import styled from 'styled-components';
import callmode from '../images/callmode.png';
import { Link } from 'react-router-dom';
import { useLanguageData } from '@/common/hooks/useLanguageData';
import { LanguageContext } from '@/common/contexts/LanguageContext';

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100vw;
  margin-top: 18px;
`;

const Box = styled(Link)`
  position: relative;
  background-image: url(${(props) => props.image});
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 90vw;
  margin-left: 5vw;
  height: 140px;
  flex-direction: column;
  gap: 14px;
  margin-top: 12px;
  border-radius: 8px;
  overflow: hidden;
  text-decoration: none;
`;

const Subtitle = styled(Link)`
  color: #29185e;
  font-family: 'Work Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 123.5%; /* 17.29px */
  letter-spacing: 0.28px;
  text-align: left;
  margin-left: 5vw;
  z-index: 1;
  text-decoration: none;
`;

const TitleTop = styled(Link)`
  color: #29185e;
  font-family: 'Work Sans';
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%; /* 25.2px */
  text-align: left;
  width: 360px;
  margin-left: 5vw;
  text-decoration: none;

  display: flex;
  align-items: center;
  justify-content: left;
  gap: 12px;
`;

const NewOne = styled.div`
  display: inline-flex;
  padding: 1px 5px;
  justify-content: center;
  align-items: center;
  gap: 10px;

  border-radius: 4px;
  background: #03da9a;

  color: #29185e;
  font-family: 'Work Sans';
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 14px */
`;

const CallMode = () => {
  const data = useLanguageData('learn.callMode');
  const { lang } = useContext(LanguageContext);

  return (
    <>
      {lang === 'pl' && (
        <Wrapper>
          <TitleTop to={'/learn/call-mode'}>
            {data?.title} {data?.new && <NewOne>{data?.new}</NewOne>}
          </TitleTop>
          <Subtitle to={'/learn/call-mode'}>{data?.subtitle}</Subtitle>
          <Box image={callmode} to={'/learn/call-mode'}></Box>
        </Wrapper>
      )}
    </>
  );
};

export default CallMode;
