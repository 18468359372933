import Api from '../api';
const { axios: api } = Api;
const PREFIX = 'word-lesson-top-type';

export const getWordLessonTopType = async (payload) => {
  const data = await api.get(`/${PREFIX}/all`, payload);
  return { data: data.data };
};

export const getWordLessonCustomerTopType = async (payload) => {
  const data = await api.get(`/${PREFIX}/customer-all`, payload);
  return { data: data.data };
};

export const loadHobbyData = async (payload) => {
  const data = await api.get(`/${PREFIX}/hobby-data/${payload}`);
  return { data: data.data };
};
