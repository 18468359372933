import { createContext, useRef, useState } from 'react';
import MessageQueue from './MessageQueue';
import { v4 as uuid } from 'uuid';

export const MessageContext = createContext();

export const MessageContextProvider = ({ children }) => {
  const [messages, setMessages] = useState([]);
  const ref = useRef();

  ref.current = messages;

  function addMessage(caption, type = 'info', timeout = 3000) {
    const id = uuid();
    setMessages((prev) => [
      ...prev.slice(-1),
      {
        id,
        type,
        caption,
        timeout: setTimeout(() => {
          removeMessage(id);
        }, timeout),
      },
    ]);
  }

  function removeMessage(id) {
    setMessages(ref.current.filter((msg) => msg.id !== id));
  }

  return (
    <MessageContext.Provider
      value={{
        addMessage,
        removeMessage,
        messages,
      }}
    >
      <MessageQueue messages={messages} removeMessage={removeMessage} />
      {children}
    </MessageContext.Provider>
  );
};
