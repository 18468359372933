import axios from 'axios';
import TokenManager from './tokenManager';

class Api {
  constructor() {
    this.axios = this.createAxios();
    this.tokenManager = new TokenManager(this.axios);
    this.useInterceptors();
  }

  useInterceptors() {
    this.axios.interceptors.response.use(
      (response) => response,
      (error) => {
        // if (error.response.status === 401) {
        //   this.tokenManager.removeToken();
        //   userManager.removeUser();
        //   window.location = '/login';
        // } else if (error.response.status === 403) {
        //   this.tokenManager.removeToken();
        //   userManager.removeUser();
        //   window.location = '/login';
        // } else {
        //   throw new Error(error?.response?.data?.message);
        // }
      },
    );
  }

  createAxios() {
    return axios.create({ baseURL: process.env.REACT_APP_BACKEND_URL });
  }
}

const api = new Api();

export default api;
