import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import ArrowLeft from './images/arrow-left.svg';
import { useMutation } from 'react-query';
import { loadUserLearningData } from '@/api/query/user';
import Loading from '@/common/components/Loading';
import { MessageContext } from '@/common/contexts/message-context/MessageContext';
import { useNavigate } from 'react-router-dom';
import { changeUserDailyMinutes } from '@/api/query/customer';
import { useLanguageData } from '@/common/hooks/useLanguageData';

const IconTitleWrapper = styled.div`
  width: 12px;
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const TopBar = styled.div`
  width: 100%;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (min-width: 600px) {
    padding: 0 20px;
    max-width: 1200px;
  }
`;

const Title = styled.div`
  color: var(--Text-color, #29185e);
  text-align: center;
  font-family: 'Work Sans';
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.48px;
`;

const Card = styled.div`
  margin-top: 20px;
  border-radius: 16px;
  background: #fff;
  width: 90vw;
  margin: 20px auto;

  @media (min-width: 600px) {
    width: 400px;
    border: 1px solid #d9d9d9;
    max-height: 50vh;
    overflow: auto;
  }
`;

export const LevelOptionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
`;

export const InputWrapper = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  cursor: pointer;

  label {
    cursor: pointer;
    font-weight: ${(props) => (props.isActive ? '600' : 'normal')};
    color: #05275d;
    text-align: center;
    font-family: 'Work Sans';
    font-size: 12px;
    font-style: normal;
    line-height: 140%;
  }

  input {
    margin: 0;
  }
`;

const MinutesElement = styled.div`
  width: 100%;
  display: inline-flex;
  cursor: pointer;
  padding: 16px 14px 16px 14px;
  box-sizing: border-box;
  flex-direction: column;
  align-items: flex-start;
  gap: 6px;
  border-radius: ${(props) => props.borderRadius};
  background: ${(props) => (props.isActive ? '#730bdb' : 'white')};
  border-bottom: 1px solid #ece5f2;
`;

const TitleBox = styled.div`
  color: ${(props) => (props.isActive ? '#F4F6F8' : '#29185E')} !important;
  text-align: center;
  font-family: 'Work Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 123.5%; /* 17.29px */
  letter-spacing: 0.28px;
`;

const Description = styled.div`
  color: ${(props) => (props.isActive ? '#F4F6F8' : '#29185E')} !important;
  font-family: 'Work Sans';
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%; /* 14.4px */
`;

const SubTilte = styled.div`
  color: #29185e;
  text-align: center;
  font-family: 'Work Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 123.5%; /* 17.29px */
  letter-spacing: 0.28px;
`;

const ProfileDailyMinutes = () => {
  const [userData, setUserData] = useState({});
  const bottom = useLanguageData('profile.bottom');

  const navigate = useNavigate();

  const { addMessage } = useContext(MessageContext);

  const loadUserMutation = useMutation({
    mutationFn: () => loadUserLearningData(),
    onSuccess: ({ data }) => {
      setUserData(data);
    },
  });

  const changeUserDailyMinutesMutation = useMutation({
    mutationFn: (data) => changeUserDailyMinutes(data),
    onSuccess: ({ data }) => {
      loadUserMutation.mutate();
      addMessage(bottom.dailyTargetChanged, 'success');
    },
    onError: () => {
      addMessage(bottom.error, 'error');
    },
  });

  const handleChangeDailyMinutes = (type) => {
    const payload = {
      type,
    };

    changeUserDailyMinutesMutation.mutate(payload);
  };

  useEffect(() => {
    loadUserMutation.mutate();
  }, []);

  return (
    <>
      {(changeUserDailyMinutesMutation.isLoading ||
        loadUserMutation.isLoading) && <Loading />}
      <TopBar style={{ marginTop: '24px' }}>
        <IconTitleWrapper>
          <img src={ArrowLeft} onClick={() => navigate('/profile/learning')} />
        </IconTitleWrapper>
        <Title>{bottom.dayTarget}</Title>
        <IconTitleWrapper />
      </TopBar>
      <SubTilte>{bottom.changeDailyTarget}</SubTilte>
      <Card>
        {bottom.lessonTime.map((c) => (
          <MinutesElement
            isActive={userData?.dailyMinutes == c.value}
            borderRadius={c.borderRadius}
            onClick={() => handleChangeDailyMinutes(c.value)}
          >
            <TopBar>
              <TitleBox isActive={userData.dailyMinutes == c.value}>
                {c.name}
              </TitleBox>
              <TitleBox isActive={userData.dailyMinutes == c.value}>
                {c.time}
              </TitleBox>
            </TopBar>
            <Description isActive={userData.dailyMinutes == c.value}>
              {c.dsecription}
            </Description>
          </MinutesElement>
        ))}
      </Card>
    </>
  );
};

export default ProfileDailyMinutes;
