import React, { useEffect, useState } from "react";
import TranslationIcon from "@/common/images/icon-translate.svg";
import { CustomImage, CustomText } from "./translation.styles";

const Translation = ({ show, setShowTrnaslation, demo = false }) => {
  const [showDemo, setShowDemo] = useState(demo);

  useEffect(() => {
    if (show) {
      setTimeout(() => {
        setShowDemo(() => false);
      }, 10000);
    }
  }, [show]);
  return (
    <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
      {show && (
        <CustomImage
          className={`fade__in__animation ${demo ? "wiggle" : ""}`}
          src={TranslationIcon}
          alt="translation-icon"
          onClick={() => setShowTrnaslation((prev) => !prev)}
        />
      )}
      {showDemo && show && (
        <CustomText className="fade__in__animation">
          Chcesz sprawdzić tłumaczenie?
        </CustomText>
      )}
    </div>
  );
};

export default Translation;
