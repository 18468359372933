import { getAiPersons } from '@/api/query/ai-person';
import React, { useEffect, useState } from 'react';
import { Howl } from 'howler';
import { Carousel } from 'react-responsive-carousel';
import styled from 'styled-components';
import LeftArr from './images/arr-left.svg';
import RightArr from './images/arr-right.svg';
import VolumeBlank from './images/volume.svg';
import SpeakAnimation from '../icons/speak_animation.gif';
import { useMutation } from 'react-query';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import Loading from '@/common/components/Loading';
import { useLanguageData } from '@/common/hooks/useLanguageData';

const Title = styled.div`
  color: #29185e;
  text-align: center;
  font-family: 'Work Sans';
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%; /* 33.6px */
  margin: 0;
`;

const Subtitle = styled.div`
  color: #29185e;
  text-align: center;
  font-family: 'Work Sans';
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-top: 6px;
`;

const Name = styled.p`
  color: #05275d;
  font-family: 'Work Sans';
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.64px;
  text-transform: uppercase;
  text-align: left;
  padding: 0px 10px;
  margin: 0;
  margin-top: 12px;
`;

const Description = styled.div`
  font-size: 10px;
  color: #29185e;
  font-family: 'Work Sans';
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%; /* 14.4px */
  max-width: 260px;
  height: 40px;
`;

const SubmitButton = styled.button`
  cursor: pointer;
  display: inline-flex;
  width: 192px;
  padding: 14px 0px 14px 0px;
  justify-content: center;
  align-items: center;
  color: white;

  color: #fff;
  text-align: center;
  font-family: 'Work Sans';
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.64px;
  text-transform: uppercase;

  background: linear-gradient(91deg, #4f17aa 1.48%, #ac02f1 99.24%);
  margin-top: 30px;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const TagsWrapper = styled.div`
  display: flex;
  justify-content: left;
  gap: 5px;
  margin: 14px 10px;
`;

const TageElement = styled.div`
  display: flex;
  padding: 0px 12px;
  justify-content: center;
  align-items: center;
  gap: 10px;

  color: #05275d;
  font-family: 'Work Sans';
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%; /* 16.8px */

  border-radius: 10px;
  background: rgba(79, 167, 180, 0.2);
`;

const Voice = styled.button`
  cursor: pointer;
  margin: 0px 0 10px 0;
  display: flex;
  width: 52px;
  height: 52px;
  padding: 15px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;

  border-radius: 39px;
  border-radius: 39px;
  background: linear-gradient(
    243deg,
    rgba(191, 65, 251, 0.92) -3.1%,
    #06cff1 47.2%,
    #8befd1 97.5%,
    rgba(5, 210, 255, 0.98) 147.8%,
    #b35cfa 198.1%
  );
  position: absolute;
  top: 220px;

  :hover {
    transform: scale(1);
  }
`;

export const OpinionElement = styled.div`
  width: 240px;
  height: 350px;
`;

export const OpinionsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
  margin-top: 30px;

  @media (max-width: 600px) {
    display: none;
  }
`;
const WrapperIcon = styled.div`
  top: 43%;
  position: absolute;
  z-index: 99999;
  border: none;
`;

const CarouselWrapper = styled.div`
  margin-top: 16px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  position: relative;
`;

const TotursWrapper = styled.div`
  display: flex;
  justify-content: center;
  gap: 20px;
`;

const WrapperOthers = styled.div`
  @media (max-width: 800px) {
    display: none;
  }
`;

const PickTutor = ({ setPage, setData }) => {
  const { title, subtitle, next } = useLanguageData('welcome.firstScreen');

  const [current, setCurrent] = useState(3);
  const [listening, setListening] = useState(false);
  const [tutors, setTutors] = useState([]);

  const handlePlay = (base64) => {
    const prefix = 'data:audio/wav;base64,';
    const audio = new Howl({
      src: [prefix + base64],
      onend: () => {
        setListening(false);
      },
    });
    setListening(true);
    audio?.play();
  };

  const getAllAiTutors = useMutation({
    mutationFn: () => getAiPersons(),
    onSuccess: ({ data }) => {
      setTutors(data);
    },
  });

  const handleChange = (e) => {
    setCurrent(() => e);
  };

  useEffect(() => {
    getAllAiTutors.mutate();
  }, []);

  return (
    <>
      {getAllAiTutors.isLoading && <Loading />}
      <Title>{title}</Title>
      <Subtitle>{subtitle}</Subtitle>
      <CarouselWrapper>
        <TotursWrapper>
          {tutors.length > 0 && (
            <>
              <WrapperOthers>
                {' '}
                <div style={{ height: '280px' }}>
                  <img
                    src={tutors[current > 0 ? current - 1 : 3]?.image?.base64}
                    style={{
                      width: 'auto',
                      height: '100%',
                      borderRadius: '12px',
                      opacity: '0.2',
                    }}
                  />
                </div>
              </WrapperOthers>

              <Carousel
                axis="horizontal"
                selectedItem={3}
                autoPlay={false}
                infiniteLoop={true}
                width="300px"
                showThumbs={false}
                showIndicators={false}
                onChange={handleChange}
                showStatus={false}
                renderArrowPrev={(onClickHandler, hasPrev, label) => (
                  <WrapperIcon
                    onClick={onClickHandler}
                    style={{
                      left: '0',
                      cursor: 'pointer',
                      padding: '20px 20px 20px 0px',
                    }}
                  >
                    <img src={LeftArr} />
                  </WrapperIcon>
                )}
                renderArrowNext={(onClickHandler, hasNext, label) => (
                  <WrapperIcon
                    onClick={onClickHandler}
                    style={{
                      right: '0px',
                      cursor: 'pointer',
                      padding: '20px 0px 20px 20px',
                    }}
                  >
                    <img src={RightArr} />
                  </WrapperIcon>
                )}
              >
                {tutors?.map((element, index) => (
                  <div style={{ height: '280px' }} key={element._id}>
                    <img
                      src={element.image.base64}
                      style={{
                        width: 'auto',
                        height: '100%',
                        borderRadius: '12px',
                      }}
                    />
                  </div>
                ))}
              </Carousel>
              <WrapperOthers>
                <div style={{ height: '280px' }}>
                  <img
                    src={tutors[current < 3 ? current + 1 : 0]?.image?.base64}
                    style={{
                      width: 'auto',
                      height: '100%',
                      borderRadius: '12px',
                      opacity: '0.2',
                    }}
                  />
                </div>
              </WrapperOthers>
            </>
          )}
        </TotursWrapper>
        <Voice
          type="button"
          onClick={() => handlePlay(tutors[current]?.voice?.base64)}
          style={{
            background: 'white',
          }}
        >
          <img
            src={listening ? SpeakAnimation : VolumeBlank}
            style={{ width: '60px' }}
          />
        </Voice>
        <Name>{tutors[current]?.name}</Name>
        <TagsWrapper>
          {tutors[current]?.tags.map((c) => (
            <TageElement style={{ background: c.color }}>{c.tag}</TageElement>
          ))}
        </TagsWrapper>
        <Description>{tutors[current]?.description}</Description>
        <SubmitButton
          onClick={() => {
            setData((prev) => ({
              ...prev,
              tutor: tutors[current],
            }));
            setPage(() => 3);
          }}
        >
          {next}
        </SubmitButton>
      </CarouselWrapper>
    </>
  );
};

export default PickTutor;
