import moment from "moment";
import polish from "moment/locale/pl";
import spanish from "moment/locale/es";
import german from "moment/locale/de";

export const langMoment = (lang = "pl") => {
  moment.locale(lang, [polish, spanish, german]);

  return moment;
};
