import Api from '../api';
const { axios: api } = Api;
const PREFIX = 'stripe';

export const getStripeSession = async () => {
  const data = await api.post(`/${PREFIX}/create-session`);
  return { data: data.data };
};

export const createStrpiePayment = async (payload) => {
  const data = await api.post(`/${PREFIX}/create-payment`, payload);
  return { data: data.data };
};

export const createStrpiePaymentShort = async (payload) => {
  const data = await api.post(`/${PREFIX}/create-payment-short`, payload);
  return { data: data.data };
};

export const createStrpieSubscriptionInApp = async (payload) => {
  const data = await api.post(`/${PREFIX}/create-subscripion-in-app`, payload);
  return { data: data.data };
};

export const createStrpieSubscriptionCheckout = async (payload) => {
  const data = await api.post(
    `/${PREFIX}/create-subscripion-checkout`,
    payload,
  );
  return { data: data.data };
};
