import React, { useContext, useMemo } from 'react';
import { LanguageContext } from '@/common/contexts/LanguageContext';
import OnePagePayuPayment from './components/payu/OnePagePayuPayment';
import { STRIPE_ONE_OFF, STRIPE_SUBSCRIBTION } from '../checkout/Checkout';
import OnePageStripeSubscribtionPayment from './components/stripe/OnePageStripeSubscribtionPayment';
import OnePageStripeOneTimePayment from './components/stripe/OnePageStripeOneTimePayment';

export const PAYU_OPERATOR_COUNTRIES = ['pl'];

const PromoCheckout = () => {
  const { lang } = useContext(LanguageContext);

  const paymentPage = useMemo(() => {
    if (PAYU_OPERATOR_COUNTRIES.includes(lang)) {
      return <OnePagePayuPayment />;
    }

    if (STRIPE_SUBSCRIBTION.includes(lang)) {
      return <OnePageStripeSubscribtionPayment />;
    }

    if (STRIPE_ONE_OFF.includes(lang)) {
      return <OnePageStripeOneTimePayment />;
    }

    return <></>;
  }, [lang]);

  return <div>{paymentPage}</div>;
};

export default PromoCheckout;
