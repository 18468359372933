import React, { useEffect, useState } from 'react';
import { DemoConversationContext } from '@/common/contexts/DemoConversation';
import {
  ContentWrapper,
  DemoSectionWrapper,
  MessageBox,
} from './talking.styles';

import { TalkiMessage } from './components';
import { useMutation } from 'react-query';
import { createDailyConversation } from '@/api/query/lead-history';
import { Wrapper } from '../home/Home.styles';
import { findCustomer } from '@/api/query/customer';
import NewTopTileHeader from '@/common/components/chat/NewTopTileHeader';
import { useParams } from 'react-router-dom';

export const SPEAKER_OPTIONS = {
  LEO: 'Leo',
  HENRY: 'Henry',
  SARAH: 'Sarah',
  RUBY: 'Ruby',
};

const Talking = ({ setPageNumber }) => {
  const [dailyConversation, setDailyConversation] = useState();
  const [data, setData] = useState({});
  const [showButtoReccord, setShowButtoReccord] = useState(false);
  const [showLoading, setShowLoading] = useState();
  const [showText2, setShowText2] = useState(true);
  const [user, setUser] = useState({});
  const { id } = useParams();

  const handleDailyConversationMutation = useMutation({
    mutationFn: (payload) => createDailyConversation(payload),
    onSuccess: (data) => {
      setDailyConversation(data);
    },
  });

  const getCustomerMutation = useMutation({
    mutationFn: () => findCustomer(),
    onSuccess: ({ data }) => {
      setUser(data);
    },
  });

  useEffect(() => {
    const payload = {
      news: id,
    };

    handleDailyConversationMutation.mutate(payload);
    getCustomerMutation.mutate();
  }, []);

  useEffect(() => {
    window.document.title = 'Synthi Ai - chat';
  }, []);

  return (
    <Wrapper>
      <NewTopTileHeader
        image={user?.speaker?.icon?.base64}
        name={user?.speaker?.name}
        backTo={'/learn'}
      />
      <DemoSectionWrapper>
        <ContentWrapper>
          <DemoConversationContext.Provider
            value={{
              data,
              setData,
              showButtoReccord,
              setShowButtoReccord,
              showLoading,
              setShowLoading,
              setPageNumber,
              showText2,
              setShowText2,
              dailyConversation,
            }}
          >
            <MessageBox id="chat-box">
              <TalkiMessage />
              <div id="bottom-info" />
            </MessageBox>
          </DemoConversationContext.Provider>
        </ContentWrapper>
      </DemoSectionWrapper>
    </Wrapper>
  );
};

export default Talking;
