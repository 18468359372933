import React from 'react';
import styled from 'styled-components';

const Circle = styled.div`
  width: 10px;
  height: 10px;
  border: 2px solid #cdc8e1;
  border-top: 2px solid #29185e;
  border-radius: 50%;
  animation: spin 1s linear infinite;

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

const LoadingCircle = () => {
  return <Circle />;
};

export default LoadingCircle;
