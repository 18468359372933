import { findCustomer } from "@/api/query/customer";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useMutation } from "react-query";
import editProfileImage from "../images/profile.svg";
import {
  ImageWrapper,
  MainDataWrapper,
  UserDataWrapper,
  UserNameWrapper,
  Wrapper,
} from "../profile.styles";
import styled from "styled-components";
import ArrLeft from "./images/arr-left.svg";
import ArrRight from "./images/arr-right.svg";
import { useNavigate } from "react-router-dom";
import Loading from "@/common/components/Loading";
import ProiflePicture from "./images/profile-picture.svg";
import ChangePasswordComponent from "../components/ChangePasswordComponent";
import ProfileChange from "../components/ProfileChange";
import ChangeName from "../components/ChangeName";
import { useLanguageData } from "@/common/hooks/useLanguageData";

const EmialWrapper = styled.div`
  color: #7f7c85;
  font-family: "Work Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 110%; /* 15.4px */
  margin-top: -5px;
  margin-left: 25px;
`;

const TopWrpper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 90vw;
  margin: 0 auto;
  box-sizing: border-box;
`;

const Imitation = styled.div`
  width: 8px;
`;

const Card = styled.div`
  width: 90vw;
  height: fit-content;
  flex-shrink: 0;
  border-radius: 8px;
  background: #fff;
  margin-top: 15px;
  max-width: 500px;
`;

const ProfileName = styled.div`
  color: #29185e;
  text-align: center;
  font-family: "Work Sans";
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.4px;
`;

const ArrWrapper = styled.div`
  width: 8px;
  cursor: pointer;

  img {
    width: 100%;
    height: auto;
  }
`;

const ChangeWrapper = styled.div`
  display: inline-flex;
  padding: 6px 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 41px;
  background: #ece5f2;
  cursor: pointer;

  color: #29185e;
  font-family: "Work Sans";
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 123.5%; /* 12.35px */
  letter-spacing: 0.2px;
`;

const NameWrapper = styled.div`
  color: #29185e;
  font-family: "Work Sans";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 16.8px */
`;

const WrapperSection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 10px 22px;
`;

const Border = styled.div`
  margin: 0 auto;
  width: 80vw;
  height: 1px;
  background-color: #ece5f2;
  max-width: 450px;
`;

const ProfileEdit = () => {
  const top = useLanguageData("profile.top");

  const navigate = useNavigate();
  const [data, setData] = useState({});
  const [changePassowrd, setChangePassowrd] = useState();
  const [changeProifle, setChangeProifle] = useState();
  const [changeName, setChangeName] = useState();

  const userMutation = useMutation({
    mutationFn: () => findCustomer(),
    onSuccess: ({ data }) => setData(data),
  });

  useEffect(() => {
    if (!changeName && !changeProifle && !changePassowrd) {
      userMutation.mutate();
    }
  }, [changePassowrd, changeProifle, changeName]);

  return (
    <>
      {userMutation.isLoading && <Loading />}
      <Wrapper style={{ gap: "0px" }}>
        <TopWrpper>
          <ArrWrapper>
            <img src={ArrLeft} onClick={() => navigate("/profile")} />
          </ArrWrapper>
          <ProfileName>{top.profile}</ProfileName>
          <Imitation />
        </TopWrpper>
        <MainDataWrapper style={{ marginTop: "20px" }}>
          <div
            style={{ position: "relative" }}
            onClick={() => setChangeProifle(true)}
          >
            <ImageWrapper>
              <img
                alt=""
                src={data?.user_profile?.base64 || editProfileImage}
                style={{ width: "100%", height: "auto", cursor: "pointer" }}
              />
            </ImageWrapper>
          </div>
          <UserDataWrapper>
            <UserNameWrapper>
              {data?.name} {data?.surname}{" "}
            </UserNameWrapper>
            <EmialWrapper style={{ marginLeft: "0" }}>
              {top.accessTo}{" "}
              {moment(data?.order?.expired_date).format("DD.MM.YYYY")}
            </EmialWrapper>
          </UserDataWrapper>
        </MainDataWrapper>
        <Card>
          <WrapperSection>
            <div>
              <img src={ProiflePicture} />
            </div>
            <NameWrapper> {top.profileImage}</NameWrapper>
            <ChangeWrapper onClick={() => setChangeProifle(true)}>
              {top.change}{" "}
              <div>
                <img src={ArrRight} />
              </div>
            </ChangeWrapper>
          </WrapperSection>
          <Border />
          <WrapperSection>
            <NameWrapper>{top.nameAndSurname}</NameWrapper>
            <ChangeWrapper onClick={() => setChangeName(true)}>
              {top.change}{" "}
              <div>
                <img src={ArrRight} />
              </div>
            </ChangeWrapper>
          </WrapperSection>
          <Border />
          <WrapperSection>
            <NameWrapper>{top.password}</NameWrapper>
            <ChangeWrapper onClick={() => setChangePassowrd(true)}>
              {top.change}{" "}
              <div>
                <img src={ArrRight} />
              </div>
            </ChangeWrapper>
          </WrapperSection>
        </Card>
      </Wrapper>
      {changePassowrd && (
        <ChangePasswordComponent setClose={setChangePassowrd} />
      )}
      {changeProifle && <ProfileChange setClose={setChangeProifle} />}
      {changeName && <ChangeName setClose={setChangeName} />}
    </>
  );
};

export default ProfileEdit;
