import React from "react";
import styled from "styled-components";
import ArrRight from "./images/arr-right.svg";
import { Link } from "react-router-dom";

const WrapperWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100vw;
`;

const Wrapper = styled(Link)`
  height: 110px;
  text-decoration: none;
  border-radius: 10px;
  background: #fff;
  box-shadow: 0px 0px 11px 0px rgba(101, 6, 117, 0.1);
  margin-top: 30px;
  display: flex;
  align-items: center;
  width: 94vw;
  margin-left: 3vw;
`;

const ImageWrapper = styled.div`
  position: relative;
  min-width: 70px;
  max-width: 70px;
  margin: 10px;

  img {
    width: 100%;
    height: auto;
  }
`;

const Point = styled.div`
  width: 12px;
  height: 12px;
  flex-shrink: 0;
  border-radius: 39px;
  background: #01b07c;
  position: absolute;
  bottom: 10px;
  right: 0px;
`;

const TopMessage = styled.div`
  display: inline-flex;
  padding: 10px 10px 10px 10px;
  align-items: center;
  border-radius: 10px 10px 10px 0px;
  background: #ece5f2;
  color: #29185e;
  font-family: Lato;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 123.5%; /* 17.29px */
  letter-spacing: 0.28px;
  float: left;
`;

const BottomMessage = styled(Link)`
  text-decoration: none;
  cursor: pointer;
  margin-top: 12px;
  float: right;
  display: flex;
  min-width: 200px;
  max-width: 240px;
  padding: 8px 8px;
  justify-content: center;
  align-items: center;
  gap: 10px;

  border-radius: 27px;
  background: linear-gradient(91deg, #4f17aa 1.48%, #ac02f1 99.24%);
  color: #fff;
  font-family: "Work Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 110%;
`;

const TalkWith = ({ icon, translation }) => {
  return (
    <WrapperWrapper>
      <Wrapper to="/learn/chat">
        <ImageWrapper>
          <img src={icon} />
          <Point />
        </ImageWrapper>
        <div style={{ margin: "12px", width: "100%" }}>
          <TopMessage>{translation.question}</TopMessage>
          <BottomMessage to="/learn/chat">
            {translation.answer} <img src={ArrRight} />{" "}
          </BottomMessage>
        </div>
      </Wrapper>
    </WrapperWrapper>
  );
};

export default TalkWith;
