import React from 'react';
import ReactFacebookLogin from 'react-facebook-login';
import { useLanguageData } from '../hooks/useLanguageData';

const FacebookAuth = ({ handleCallback }) => {
  const data = useLanguageData('common');

  return (
    <>
      <ReactFacebookLogin
        appId={process.env.REACT_APP_FACEBOOK_APP_ID}
        autoLoad={false}
        fields="name,email"
        callback={handleCallback}
        textButton={data.continueWithFB}
        cssClass="kep-login-facebook"
        icon="fa-facebook"
      />
    </>
  );
};

export default FacebookAuth;
